import React from 'react'
import {
  Menu,
} from "semantic-ui-react"
import { HashLink as Link } from 'react-router-hash-link';

function NavigationMenuItems(props) {
  return (
    props.showMenu ?
      <React.Fragment>
        <Menu.Item as={Link} to="/home#howitworks" onClick={props.onClick} exact="true">
          How it works
        </Menu.Item>
        <Menu.Item as={Link} to="/home#pricing" onClick={props.onClick} exact="true">
          Pricing
        </Menu.Item>
      </React.Fragment>
      : null
  )
}

export default NavigationMenuItems

