import React from "react"
import { Button, Modal } from "semantic-ui-react"

const confirmationModal = (props) => {
  return (
    <Modal size="tiny" open={props.open} onClose={props.closeAction}>
      <Modal.Header>{props.header}</Modal.Header>
      {props.message ? (
        <Modal.Content>
          <p>{props.message}</p>
        </Modal.Content>
      ) : null}
      <Modal.Actions>
        <Button
          negative
          icon="cancel"
          labelPosition="right"
          content={props.noText}
          onClick={props.closeAction}
        />
        <Button
          positive
          loading={props.loading}
          disabled={props.loading}
          icon="checkmark"
          labelPosition="right"
          content={props.yesText}
          onClick={props.confirmAction}
        />
      </Modal.Actions>
    </Modal>
  )
}


export default confirmationModal