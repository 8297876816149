import React, { Component } from "react"
import { connect } from "react-redux"
import { Card, List, Grid } from "semantic-ui-react"
import TodoistListDescription from "./TodoistListDescription"
import CalendarListDescription from "./CalendarListDescription"
import StatusIcon from "./StatusIcon"
import SyncSettings from "./SyncSettings"

export class Status extends Component {

  
  render() {
    const todoistConnected = this.props.auth?.todoSettings?.provider && true
    const noTimeboxSettings = this.props.auth?.todoSettings?.timeboxSettings?.length === 0
    const calendarConnected = this.props.auth.calendarSettings?.provider && true
    const noCalendarSelected = !(this.props.auth?.calendarSettings?.calendarId && true)
    const syncActive = this.props.auth?.syncActive
    
    return (
      <Card raised fluid>
        <Card.Content>
          <Grid stackable>
            <Grid.Row>
              <Grid.Column width={8}>
                <List relaxed>
                  <List.Item>
                    <StatusIcon ok={todoistConnected} warn={noTimeboxSettings} />
                    <List.Content>
                      <List.Header>Todoist Account</List.Header>
                      <TodoistListDescription connected={this.props.auth?.todoSettings?.provider} timeboxSettingsCount={this.props.auth?.todoSettings?.timeboxSettings?.length} />
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <StatusIcon ok={calendarConnected} warn={noCalendarSelected} />
                    <List.Content>
                      <List.Header>Calendar Account</List.Header>
                      <CalendarListDescription connected={this.props.auth.calendarSettings?.provider} calendarId={this.props.auth?.calendarSettings?.calendarId} />
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <StatusIcon ok={syncActive} />
                    <List.Content>
                      <List.Header>Sync Status</List.Header>
                      <List.Description>{syncActive ? "Your sync is up and running" : "Sync is not active."}</List.Description>
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <SyncSettings disabled={!syncActive && (!todoistConnected || noTimeboxSettings || !calendarConnected || noCalendarSelected)}/>
                  </List.Item>
                </List>
              </Grid.Column>
              <Grid.Column width={8}>
                <List relaxed>
                  {this.props.auth?.todoSettings?.provider ?
                    (
                      <List.Item>
                        <List.Icon name="time" size="large" color="grey" verticalAlign="middle" />
                        <List.Content>
                          <List.Header>{this.props.auth?.todoSettings?.timezone?.timezone}</List.Header>
                          <List.Description>Your Todoist timezone</List.Description>
                        </List.Content>
                      </List.Item>
                    )
                    : null}
                  <List.Item>
                    <List.Icon name="time" size="large" color="grey" verticalAlign="middle" />
                    <List.Content>
                      <List.Header>{Intl.DateTimeFormat().resolvedOptions().timeZone}</List.Header>
                      <List.Description>Your current timezone</List.Description>
                    </List.Content>
                  </List.Item>
                </List>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Card.Content>
      </Card >
    )
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  }
}

export default connect(mapStateToProps)(Status)
