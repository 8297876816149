import React, { Component, createRef } from 'react'
// import ReactMarkdown from "react-markdown"
import CommonMark from "commonmark"
import ReactRenderer from "commonmark-react-renderer"
import DocumentationPath from "../../assets/documentation.md"
import {
  Container,
  List,
  Header,
  Message
} from "semantic-ui-react"


export default class Documentation extends Component {
  constructor(props) {
    super(props)

    this.state = { documentation: "" }
  }

  contextRef = createRef()

  componentWillMount() {
    fetch(DocumentationPath).then((response) => response.text()).then((text) => {
      this.setState({ documentation: text })
    })
  }


  render() {

    const parser = new CommonMark.Parser()
    const renderer = new ReactRenderer({
      renderers: {
        item: function (props) { return <List.Item>{props.children}</List.Item> },
        list: function (props) { return <List bulleted>{props.children}</List> },
        heading: function (props) { return <Header as={"h" + props.level} id={props.children}>{props.children}</Header> },
        blockquote: function (props) { return <Message compact info>{props.children}</Message> }
      }
    })
    var node = parser.parse(this.state.documentation)
    var result = renderer.render(node)

    return (
      <Container text style={{ marginTop: "5em" }}>
        {result}
      </Container>
      // <Container style={{ marginTop: "7em" }}>
      //   <Grid centered columns={2}>
      //     <Grid.Column>
      //       <Ref innerRef={this.contextRef}>
      //         <Container>
      //           {result}
      //           <Rail position='left'>
      //             <Sticky context={this.contextRef}>
      //               <List style={{ marginTop: "4em" }}>
      //                 <List.Item>
      //                   <List.Icon name='file' />
      //                   <List.Content>
      //                     <List.Header as="a" href="#Create Todoist task">Introduction</List.Header>
      //                   </List.Content>
      //                 </List.Item>
      //               </List>
      //             </Sticky>
      //           </Rail>
      //         </Container>
      //       </Ref>
      //     </Grid.Column>
      //   </Grid>
      // </Container>

    )
  }
}


