import React from "react"
import { Button, Card, Image, Label } from 'semantic-ui-react'

const integrationCard = (props) => {
  const handleRemoveIntegrationClick = () => {
    props.disconnectAction(props.provider);
  }

  let connectedPill = null
  let connectButton = null
  let additionalStatePill = null
  if (props.connected) {
    connectedPill = <Label circular style={{ backgroundColor: "#c6f6d5", color: "#22543d", paddingTop: "1 rem" }}>Connected</Label>
    if (props.additionalState ) {
      additionalStatePill = <Label circular style={{ backgroundColor: "#E8E8E8", color: "#5D5D5D", paddingTop: "1 rem" }}>{props.additionalState}</Label>
    }
    connectButton = <Button onClick={handleRemoveIntegrationClick} fluid>Remove integration</Button>
  } else {
    connectedPill = <Label circular style={{ backgroundColor: "#fed7d7", color: "#742a2a", paddingTop: "1 rem" }}>Not connected</Label>
    connectButton = props.connectLink ?
      <Button as="a" href={props.connectLink} primary fluid>Connect your {props.provider} account</Button> :
      <Button onClick={props.connectButton} primary fluid>Connect your {props.provider} account</Button>
  }


  return (
    <Card raised fluid>
      <Card.Content>
        <Image
          floated='left'
          width={50} height={50}
          src={props.logo}
        />
        <Card.Header>
          {props.provider}
        </Card.Header>
        <Card.Meta>
          {connectedPill}
          {additionalStatePill}
        </Card.Meta>
      </Card.Content>
      <Card.Content extra>
        {connectButton}
      </Card.Content>
    </Card >
  )
}

export default integrationCard