export const FETCH_USER = "FETCH_USER"
export const REVOKE_ACCESS = "REVOKE_ACCESS"

export const ADD_TIMEBOX_SETTING = "ADD_TIMEBOX_SETTING"
export const EDIT_TIMEBOX_SETTING = "EDIT_TIMEBOX_SETTING"
export const REMOVE_TIMEBOX_SETTING = "REMOVE_TIMEBOX_SETTING"

export const SET_USE_TASK_DURATION_SETTING ="SET_USE_TASK_DURATION_SETTING"
export const EDIT_TASK_DURATION_LABEL = "EDIT_TASK_DURATION_LABEL"

export const ADD_LABEL = "ADD_LABEL"
export const UPDATE_LABEL = "UPDATE_LABEL"

export const SET_CALENDAR = "SET_CALENDAR"
export const SET_EVENT_COLOR = "SET_EVENT_COLOR"
export const SET_CATEGORY = "SET_CATEGORY"
export const SET_PRIVATE_SETTING = "SET_PRIVATE_SETTING"
export const SET_TODOIST_LINK_DESCRIPTION_SETTING = "SET_TODOIST_LINK_DESCRIPTION_SETTING"
export const SET_REMINDER = "SET_REMINDER"

export const SET_ON_COMPLETE_SETTING = "SET_ON_COMPLETE_SETTING"
export const SET_ON_PRE_COMPLETE_SETTING = "SET_ON_PRE_COMPLETE_SETTING"

export const SET_SYNC_ACTIVE_SETTING = "SET_SYNC_ACTIVE_SETTING"

export const SET_WELCOME_WIZARD_SEEN = "SET_WELCOME_WIZARD_SEEN"

export const CREATE_PAYMENT_INTENT = "CREATE_PAYMENT_INTENT"
export const CREATE_PAYMENT_CUSTOMER = "CREATE_PAYMENT_CUSTOMER"