import React, { useState } from "react"
import { connect } from "react-redux"
import {
  Modal, Button, Grid, Image, Header
} from "semantic-ui-react"
import { Link } from "react-router-dom"
import * as actions from "../../redux/actions"
import { createMedia } from '@artsy/fresnel'
const { Media } = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    computer: 1024,
  },
})

export const WelcomeWizard = (props) => {

  const [wizardPage, setWizardPage] = useState(0)
  const [close, setClose] = useState(false)

  const showNextPage = () => {
    setWizardPage(wizardPage + 1)
  }

  const closeModal = () => {
    setClose(true)
    props.setWelcomeWizardSeen(true)
  }

  const getContents = (pageNumber) => {
    switch (pageNumber) {
      case 0:
        return {
          text: "Hey, it's cool you're here. Let us take a short tour to show you how to set up Tascaly to make it work best for you.",
          secondaryText: "First connect both your Todoist and calendar account. You can choose between your Google or Microsoft Office 365 calendar.",
          image: "welcome_wizard.svg",
          buttonText: "Next",
          action: showNextPage
        }
      case 1:
        return {
          text: "Tascaly uses Todoist labels to set up timeboxes. Start with the first three timebox settings and add more for your needs.",
          secondaryText: "@30Minutes, @60_min or @1_h make for good labels.",
          image: "welcome_wizard_1.png",
          buttonText: "Next",
          action: showNextPage
        }
      case 2:
        return {
          text: "Next, choose the calendar you want to sync with Todoist. Please note: if you choose your main calendar, be sure to select a separate category or color. Otherwise, all events you already have will be created as tasks. Alternatively, you can use a separate calendar for this.",
          secondaryText: "This is really important, please be very careful about this.",
          image: "welcome_wizard_2.png",
          buttonText: "Next",
          action: showNextPage
        }
      case 3:
        return {
          text: "If you have another calendar sync like Todoist's native Google calendar sync or IFTTT/Zapier integrations active, please deactivate them.",
          image: "welcome_wizard_4.svg",
          buttonText: "Next",
          action: showNextPage
        }
      case 4:
        return {
          text: "Everything set up? Check your status and activate your sync with the switch.",
          secondaryText: "Yay, you're ready to go. Create your first tasks and calendar events and see how they sync each other. Please make sure to also read the whole documentation to learn how Tascaly works in detail.",
          image: "welcome_wizard_3.png",
          buttonText: "Ok, let's go",
          action: closeModal,
          secondaryButton: <Button as={Link} secondary content="Open documentation" to="/documentation" target="_blank" />
        }
      default:
        return {}
    }
  }

  // const getWidth = () => {
  //   const isSSR = typeof window === "undefined"
  //   return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth
  // }


  return (
    <Modal
      open={props.auth && !close}
      size="small"
      closeOnEscape={false}
      closeOnDimmerClick={false}
    >
      <Modal.Header>Welcome to Tascaly <span role="img" aria-label="Hello">👋</span></Modal.Header>
      <Modal.Content style={{ height: "25rem" }}>
        <Grid stackable columns="2">
          <Grid.Column width="10">
            {/* <Responsive as={Image} size="large" src={getContents(wizardPage).image} getWidth={getWidth} minWidth={Responsive.onlyTablet.minWidth} /> */}
            <Media greaterThanOrEqual="tablet">
              <Image size="large" src={getContents(wizardPage).image} />
            </Media>
          </Grid.Column>
          <Grid.Column width="6">
            <Header as="h4">
              {getContents(wizardPage).text}
            </Header>
            <Header as="h4">
              {getContents(wizardPage).secondaryText}
            </Header>
            {getContents(wizardPage).secondaryButton}
          </Grid.Column>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button primary icon="arrow right" content={getContents(wizardPage).buttonText} onClick={getContents(wizardPage).action} labelPosition="right" />
      </Modal.Actions>
    </Modal>
  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  }
}

export default connect(mapStateToProps, actions)(WelcomeWizard)
