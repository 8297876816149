import axios from "axios"
import { FETCH_USER, REVOKE_ACCESS, ADD_TIMEBOX_SETTING, REMOVE_TIMEBOX_SETTING, EDIT_TIMEBOX_SETTING, UPDATE_LABEL, ADD_LABEL, SET_CALENDAR, SET_EVENT_COLOR, SET_CATEGORY, SET_REMINDER, SET_PRIVATE_SETTING, SET_TODOIST_LINK_DESCRIPTION_SETTING, SET_ON_PRE_COMPLETE_SETTING, SET_ON_COMPLETE_SETTING, SET_SYNC_ACTIVE_SETTING, SET_WELCOME_WIZARD_SEEN, CREATE_PAYMENT_INTENT, CREATE_PAYMENT_CUSTOMER, SET_USE_TASK_DURATION_SETTING, EDIT_TASK_DURATION_LABEL } from "./actionTypes"

export const fetchUser = () => async dispatch => {
  let res = await axios.get("/api/current_user")
  if (res.data) {

    try {
      await axios.get("/api/todoist/updateuserprofile")
    } catch (error) {
      // console.error(error)
    }
    try {
      await axios.get("/api/calendar/updateuserprofile")
    } catch (error) {
      // console.error(error)
    }
    res = await axios.get("/api/current_user")
  }
  dispatch({ type: FETCH_USER, user: res.data })
}

export const revokeAccess = (provider) => async dispatch => {
  let path = ""
  if (provider === "Google Calendar") {
    path = "google/calendar"
  } else {
    path = provider
  }

  try {
    const res = await axios.get("/auth/" + path + "/revoke")
    dispatch({ type: REVOKE_ACCESS, user: res.data })
  } catch (error) {
    console.error(error)
  }
}

export const addTimeboxSetting = (label, duration) => async dispatch => {
  try {
    const res = await axios.post("/api/timeboxsettings", { label: label, duration: duration })
    dispatch({ type: ADD_TIMEBOX_SETTING, user: res.data })
  } catch (error) {
    console.error(error)
  }
}

export const removeTimeboxSetting = (label, labelId) => async dispatch => {
  try {
    if (labelId) {
      await axios.delete("/api/todoist/label/" + labelId)
    }
    const res = await axios.delete("/api/timeboxsettings/" + label)
    dispatch({ type: REMOVE_TIMEBOX_SETTING, user: res.data })
  } catch (error) {
    console.error(error)
  }
}

export const editTimeboxSetting = (label, newLabel, duration) => async dispatch => {
  try {
    const res = await axios.patch("/api/timeboxsettings", { label: label, newLabel: newLabel, duration: duration })
    dispatch({ type: EDIT_TIMEBOX_SETTING, user: res.data })
  } catch (error) {
    console.error(error)
  }
}

export const setUseTaskDurationSetting = (setting) => async dispatch => {
  try {
    const res = await axios.patch("/api/taskdurationsettings/active", { active: setting })
    dispatch({ type: SET_USE_TASK_DURATION_SETTING, user: res.data })
  } catch (error) {
    console.error(error)
  }
}

export const editTaskDurationLabel = (label) => async dispatch => {
  try {
    const res = await axios.patch("/api/taskdurationsettings/label", { label: label })
    dispatch({ type: EDIT_TASK_DURATION_LABEL, user: res.data })
  } catch (error) {
    console.error(error)
  }
}

export const addLabel = (label, color) => async dispatch => {
  try {
    await axios.post("/api/todoist/label", { label: label, color: color })
    dispatch({ type: ADD_LABEL })
  } catch (error) {
    console.error(error)
  }
}

export const updateLabel = (labelId, color) => async dispatch => {
  try {
    await axios.patch("/api/todoist/label", { labelId: labelId, color: color })
    dispatch({ type: UPDATE_LABEL })
  } catch (error) {
    console.error(error)
  }
}

export const setCalendar = (calendarId) => async dispatch => {
  try {
    const res = await axios.patch("/api/calendar/id", { calendarId: calendarId })
    dispatch({ type: SET_CALENDAR, user: res.data })
  } catch (error) {
    console.error(error)
  }
}

export const setEventColor = (color) => async dispatch => {
  try {
    const res = await axios.patch("/api/calendar/color", { color: color })
    dispatch({ type: SET_EVENT_COLOR, user: res.data })
  } catch (error) {
    console.error(error)
  }
}

export const setCategory = (category) => async dispatch => {
  try {
    const res = await axios.patch("/api/calendar/category", { category: category })
    dispatch({ type: SET_CATEGORY, user: res.data })
  } catch (error) {
    console.error(error)
  }
}

export const setPrivateSetting = (setting) => async dispatch => {
  try {
    const res = await axios.patch("/api/calendar/private", { private: setting })
    dispatch({ type: SET_PRIVATE_SETTING, user: res.data })
  } catch (error) {
    console.error(error)
  }
}

export const setTodoistLinkDescriptionSetting = (setting) => async dispatch => {
  try {
    const res = await axios.patch("/api/calendar/todoistlinkdescription", { todoistLinkDescription: setting })
    dispatch({ type: SET_TODOIST_LINK_DESCRIPTION_SETTING, user: res.data })
  } catch (error) {
    console.error(error)
  }
}

export const setOnPreCompleteSetting = (setting) => async dispatch => {
  try {
    const res = await axios.patch("/api/settings/onprecomplete", { setting: setting })
    dispatch({ type: SET_ON_PRE_COMPLETE_SETTING, user: res.data })
  } catch (error) {
    console.error(error)
  }
}

export const setOnCompleteSetting = (setting) => async dispatch => {
  try {
    const res = await axios.patch("/api/settings/oncomplete", { setting: setting })
    dispatch({ type: SET_ON_COMPLETE_SETTING, user: res.data })
  } catch (error) {
    console.error(error)
  }
}

export const setReminder = (reminder) => async dispatch => {
  try {
    const res = await axios.patch("/api/calendar/reminder", { reminder: reminder })
    dispatch({ type: SET_REMINDER, user: res.data })
  } catch (error) {
    console.error(error)
  }
}


export const setSyncActiveSetting = (setting) => async dispatch => {
  try {
    const res = await axios.patch("/api/settings/onsyncactive", { setting: setting })
    dispatch({ type: SET_SYNC_ACTIVE_SETTING, user: res.data })
  } catch (error) {
    console.error(error)
  }
}

export const setWelcomeWizardSeen = (wizardSeen) => async dispatch => {
  try {
    const res = await axios.patch("/api/welcome/onwizardseen", { wizardSeen: wizardSeen })
    dispatch({ type: SET_WELCOME_WIZARD_SEEN, user: res.data })
  } catch (error) {
    console.error(error)
  }
}

export const createPaymentIntent = () => async dispatch => {
  try {
    // const res = await axios.post("/create-payment-intent")
    const res = await axios.post("/api/payment/create/intent", { })
    dispatch({ type: CREATE_PAYMENT_INTENT, clientSecret: res.data.clientSecret })
  } catch (error) {
    console.error(error)
  }
}

export const createPaymentCustomer = () => async dispatch => {
  try {
    const res = await axios.post("/api/payment/create/customer", { })
    dispatch({ type: CREATE_PAYMENT_CUSTOMER, user: res.data })
  } catch (error) {
    console.error(error)
  }
}

