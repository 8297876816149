import React, { Component } from 'react'
import {
  Message, Container
} from "semantic-ui-react"
import { Link, Navigate } from "react-router-dom"

export default class SubscriptionSuccess extends Component {

  state = {
    redirect: false,
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        redirect: true,
      })
    }, 3000)
  }




  render() {

    window.gtag('event', 'conversion', {
      'send_to': 'AW-615065118/MGKyCMbiypkDEJ7MpKUC',
      'transaction_id': ''
    })

    if (this.state.redirect) {
      return (
        <Navigate to={"/profile"} />
      )
    }
    return (
      <Container style={{ marginTop: "7em" }}>
        <Message floating positive>
          <Message.Header>Congratulations, you have successfully subscripbed to Tascaly</Message.Header>
          <p>You will be redirected to your profile page shortly. If not, click <Link to="/profile">here</Link></p>
        </Message>
      </Container >

    )
  }
}
