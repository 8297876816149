import React, { Component } from "react"
import { Button, Modal, Dropdown, Input, Form, Icon, Label } from 'semantic-ui-react'
import { colors } from "../UI/ColorMappings"
import convertDuration from "./DurationConverter"


class TimeboxSettingAddModal extends Component {

  constructor(props) {
    super(props)
    this.colorOptions = []
    for (const color in colors) {
      // V8 Todoist API:
      // let newOption = { key: parseInt(color), text: (<span><Icon name="tag" flipped="horizontally" style={{ color: colors[color].hexCode }} />{" "}{colors[color].name}</span>), value: colors[color].name }

      // V9 Todoist API:
      let newOption = { key: color, text: (<span><Icon name="tag" flipped="horizontally" style={{ color: colors[color].hexCode }} />{" "}{colors[color].name}</span>), value: colors[color].name }
      this.colorOptions.push(newOption)
    }
  }

  render() {
    return (
      <Modal size="tiny" open={this.props.open} onClose={this.props.onCancel}>
        <Modal.Header>
          {this.props.mode === "add"
            ? "Create a new Timebox setting"
            : "Edit Timebox setting"}
        </Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Group>
              <Form.Input
                disabled={this.props.loading}
                label="Color"
                width={5}
              >
                <Dropdown
                  options={this.colorOptions}
                  placeholder="Select a color"
                  search
                  selection
                  fluid
                  value={colors[this.props.currentColor]?.name}
                  onChange={(event, data) => this.props.onColorChanged(event, data.options.find(option => option.value === data.value))}
                />
              </Form.Input>
              <Form.Input
                disabled={this.props.loading}
                error={this.props.labelError ? { content: this.props.labelError } : false}
                label="Label"
                width={11}
              >
                <Dropdown
                  options={this.props.options}
                  placeholder='Choose an existing label or create a new one'
                  search
                  selection
                  fluid
                  allowAdditions
                  additionLabel="Add new label: "
                  value={this.props.currentLabelValue}
                  onAddItem={(event, { value }) => this.props.onNewLabel(event, { value })}
                  onChange={(event, { value }) => this.props.onLabelChanged(event, { value })}
                  onClick={this.props.onDropdownClicked}
                />
              </Form.Input>
            </Form.Group>
            <Form.Group>
              <Form.Input
                disabled={this.props.loading}
                error={this.props.durationError ? { content: this.props.durationError } : false}
                label="Duration"
                width={7}
              >
                <Input
                  placeholder="Enter duration in"
                  value={this.props.currentDurationValue}
                  label={{ basic: false, content: "minutes" }}
                  labelPosition="right"
                  onChange={(event) => this.props.onDurationChanged(event.target.value)}
                />
              </Form.Input>
              <Form.Input label="Hours" width={6}>
                <Label active={false} size="big">
                  {convertDuration(this.props.currentDurationValue) || "---"}
                </Label>
              </Form.Input>
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button negative disabled={this.props.loading} onClick={this.props.onCancel}>Cancel</Button>
          <Button positive disabled={this.props.loading || this.props.durationError || this.props.labelError} loading={this.props.loading} onClick={this.props.onSave}>Save</Button>
        </Modal.Actions>
      </Modal>
    )
  }
}

export default TimeboxSettingAddModal
