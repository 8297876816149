import React, { Component } from "react"
import { Item, Modal, Button } from 'semantic-ui-react'

class CalendarSelector extends Component {
  render() {
    return (
      <Modal size="tiny" open={this.props.open} onClose={this.props.closeAction}>
        <Modal.Header>Choose your calendar integration</Modal.Header>
        <Modal.Content>
          <Item.Group divided>
            <Item>
              <Item.Image size='mini' src='/microsoft.png' width={40} height={40}/>
              <Item.Content>
                <Button as="a" href="/auth/microsoft" fluid primary>Connect your Microsoft calendar</Button>
              </Item.Content>
            </Item>
            <Item>
              <Item.Image size='mini' src='/googlecalendar.png' />
              <Item.Content>
                <Button as="a" href="/auth/google/calendar" fluid primary>Connect your Google calendar</Button>
              </Item.Content>
            </Item>
          </Item.Group>
        </Modal.Content>
        <Modal.Actions>
          <Button
            icon="cancel"
            labelPosition="right"
            content="Cancel"
            onClick={this.props.closeAction}
          />
        </Modal.Actions>
      </Modal>
    )
  }
}

export default CalendarSelector