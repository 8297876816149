import React, { Component } from "react"
import { connect } from "react-redux"
import { Table, Button, Icon, Segment } from "semantic-ui-react"

import * as actions from "../../redux/actions"
import TimeBoxSetting from "./TimeBoxSetting"
import TimeboxSettingAddModal from "./TimeboxSettingAddModal"
import { colors } from "../UI/ColorMappings"
import TimeboxSettingRemoveModal from "./TimeboxSettingRemoveModal"

class TimeBoxSettings extends Component {

  state = {
    addSettingModalOpen: false,
    settingModalMode: null,
    originalModalLabelValue: "",
    currentModalLabelValue: "",
    currentModalDurationValue: "",
    currentModalColor: null,
    currentTodoistLabelColor: null,
    newLabel: "",
    labelToEditExistsInTodoist: true,
    loading: false,
    removeSettingModalOpen: false,
    labelToRemove: null,
    labelColorToRemove: null,
    deleteTodoistLabel: false,
    labelError: null,
    durationError: null,
    taskDurationsActive: false
  }

  onAddButtonHandler = () => {
    this.setState({
      addSettingModalOpen: true,
      settingModalMode: "add"
    })

  }

  onNewLabelHandler = (event, newLabel) => {
    this.setState({
      newLabel: newLabel.value,
      currentTodoistLabelColor: null
    })
  }


  onEditButtonHandler = (label, duration, color, labelExistsInTodoist) => {
    this.setState({
      addSettingModalOpen: true,
      settingModalMode: "edit",
      originalModalLabelValue: label,
      currentModalLabelValue: label,
      currentModalDurationValue: duration,
      labelToEditExistsInTodoist: labelExistsInTodoist,
      currentModalColor: color,
      currentTodoistLabelColor: color
    })
  }


  onCancelButtonHandler = () => {
    this.setState({
      addSettingModalOpen: false,
      currentModalLabelValue: "",
      currentModalDurationValue: "",
      currentModalColor: null,
      currentTodoistLabelColor: null,
      originalModalLabelValue: "",
      newLabel: "",
      removeSettingModalOpen: false,
      labelToRemove: null,
      labelColorToRemove: null,
      deleteTodoistLabel: false,
      labelToEditExistsInTodoist: true,
      labelError: false,
      durationError: false
    })
  }

  onLabelDropdownClicked = () => {
    this.setState({
      labelError: false
    })
  }

  onSaveButtonHandler = async () => {

    let durationError = this.checkDuration(this.state.currentModalDurationValue)
    if (durationError) {
      this.setState({
        durationError: durationError
      })
      return
    }

    let labelError = this.checkLabel(this.state.currentModalLabelValue)
    if (labelError) {
      this.setState({
        labelError: labelError
      })
      return
    }

    this.setState({
      loading: true,
    })

    if (this.state.settingModalMode === "add") {
      await this.props.addTimeboxSetting(this.state.currentModalLabelValue, this.state.currentModalDurationValue)
    } else if (this.state.settingModalMode === "edit") {
      await this.props.editTimeboxSetting(this.state.originalModalLabelValue, this.state.currentModalLabelValue, this.state.currentModalDurationValue)
    }

    if (this.state.newLabel !== "") {
      await this.props.addLabel(this.state.currentModalLabelValue, this.state.currentModalColor)
    }

    if (this.state.currentModalColor !== this.state.currentTodoistLabelColor && this.state.currentTodoistLabelColor !== null) {
      const todoistLabelId = this.findTodoistLabel(this.state.currentModalLabelValue)?.id
      const newColor = this.state.currentModalColor
      await this.props.updateLabel(todoistLabelId, newColor)
    }

    await this.props.fetchUser()
    this.setState({
      loading: false,
      addSettingModalOpen: false,
      settingModalMode: "",
      currentModalLabelValue: "",
      currentModalDurationValue: "",
      currentModalColor: null,
      currentTodoistLabelColor: null,
      originalModalLabelValue: "",
      newLabel: "",
      labelToEditExistsInTodoist: true
    })
  }

  onAddLabelHandler = async (label) => {
    this.setState({
      loading: true
    })
    const color = 38 // Teal
    await this.props.addLabel(label, color)
    await this.props.fetchUser()
    this.setState({
      loading: false
    })
  }

  onRemoveLabelHandler = async () => {
    const label = this.state.labelToRemove
    this.setState({
      loading: true,
    })
    let todoistLabel
    if (this.state.deleteTodoistLabel) {
      todoistLabel = this.findTodoistLabel(label)
    }
    await this.props.removeTimeboxSetting(label, todoistLabel?.id)
    await this.props.fetchUser()
    this.setState({
      loading: false,
      removeSettingModalOpen: false,
      labelToRemove: null,
      labelColorToRemove: null,
      deleteTodoistLabel: false
    })
  }

  onRemoveButtonHandler = async (label, todoistLabel) => {
    this.setState({
      removeSettingModalOpen: true,
      labelToRemove: label,
      labelColorToRemove: colors[todoistLabel?.color]?.hexCode,
      deleteTodoistLabel: false
    })
  }

  onDeleteTodoistLabelCheckboxHandler = (event, data) => {
    this.setState({
      deleteTodoistLabel: data.checked
    })
  }


  onChangeLabelHandler = (event, label) => {
    const todoistLabel = this.props.auth.todoSettings.labels.find(todoistLabel => label.value === todoistLabel.name)

    let color = ""
    if (todoistLabel?.color) {
      color = todoistLabel.color
    } else if (this.state.currentModalColor) {
      color = this.state.currentModalColor
    }

    this.setState({
      currentModalLabelValue: label.value,
      labelError: this.checkLabel(label.value),
      currentModalColor: color,
      currentTodoistLabelColor: color
    })
  }

  onChangeDurationHandler = (duration) => {
    this.setState({
      currentModalDurationValue: duration,
      durationError: this.checkDuration(duration)
    })
  }

  onChangeColorHandler = (event, color) => {
    this.setState({
      currentModalColor: color.key
    })
  }

  checkDuration = (duration) => {
    let error = false
    if (duration === "") {
      error = "Please enter a duration"
    } else if (isNaN(duration)) {
      error = "Please enter a number for the duration"
    } else if (!Number.isInteger(parseFloat(duration))) {
      error = "Please enter an integer (without decimal point)"
    } else if (duration <= 0 || duration > 1440) {
      error = "Please enter a number between 1 and 1440 (= 24h)"
    }
    return error
  }

  checkLabel = (label) => {
    let error = false
    // const regex = /[!"%&()=?*~#,:@|<>]/
    // const regex = /[!"&()|@]/
    if (label === "") {
      error = "Please select a label"
    // Änderung am 27.06.: Labels dürfen jetzt Leerzeichen und Sonderzeichen enthalten
    // } else if (label.indexOf(" ") >= 0) {
    //   error = "Please enter a label without spaces"
    // } else if (regex.test(label)) {
    //   error = "Please enter a label without special characters. Forbidden characters: !\"&()|@"
    } else if (label.length > 60) {
      error = "Please enter a label with no more than 60 characters"
    } else if (
      this.props.auth.todoSettings.timeboxSettings.find(timeboxSetting => timeboxSetting.label === label)
      && (label !== this.state.originalModalLabelValue || this.state.originalModalLabelValue === "")
    ) {
      error = "There is already another setting with that label"
    }
    return error
  }

  createDropDownOptions = (user) => {
    let options = []
    if (user?.todoSettings?.labels) {
      options = user.todoSettings.labels.map(label => {
        return { key: label.id, text: label.name, value: label.name, icon: <Icon name="tag" flipped="horizontally" style={{ color: colors[label.color].hexCode }} />, order: label.item_order }
      })
      options.sort((a, b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0))
    }
    if (this.state.newLabel) {
      options.splice(0, 0, { key: "new", text: this.state.newLabel, value: this.state.newLabel, icon: "plus square" })
    }
    if (!this.state.labelToEditExistsInTodoist) {
      options.splice(0, 0, { key: "notInTodoist", text: this.state.originalModalLabelValue, value: this.state.originalModalLabelValue, icon: "attention" })
    }
    return options
  }

  getTimeboxSettings = () => {
    let timeboxSettings = null
    if (this.props.auth?.todoSettings?.timeboxSettings) {
      this.props.auth.todoSettings.timeboxSettings.sort((a, b) => (a.duration > b.duration) ? 1 : -1)
      timeboxSettings = this.props.auth.todoSettings.timeboxSettings.map(timeboxSetting =>
        <TimeBoxSetting
          buttonsDisabled={this.state.taskDurationsActive}
          key={timeboxSetting.label}
          label={timeboxSetting.label}
          todoistLabel={this.findTodoistLabel(timeboxSetting.label)}
          duration={timeboxSetting.duration}
          loading={this.state.loading}
          onEdit={this.onEditButtonHandler}
          onRemove={this.onRemoveButtonHandler}
          onAdd={this.onAddLabelHandler}
        />
      )
     
    }
    
    return timeboxSettings
  }

  
  findTodoistLabel = (label) => {
    let todoistLabel = null
    if (this.props.auth?.todoSettings?.labels) {
      const todoistLabels = this.props.auth.todoSettings.labels
      todoistLabel = todoistLabels.find(element => element.name === label)
    }
    return todoistLabel
  }

  static getDerivedStateFromProps(props, state) {
    if (props.auth?.todoSettings?.taskDurationSettings) {
      return ({
        taskDurationsActive: props.auth?.todoSettings?.taskDurationSettings?.active,
      })
    }
    return null
  }

  render() {
    return (
      <Segment raised disabled={this.state.taskDurationsActive}>

        <Table stackable basic="very">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Label</Table.HeaderCell>
              <Table.HeaderCell>Duration</Table.HeaderCell>
              <Table.HeaderCell>Hours</Table.HeaderCell>
              <Table.HeaderCell textAlign="right"></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.getTimeboxSettings()}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell textAlign="right" colSpan="4k">
                <Button disabled={this.state.edit || !this.props.auth?.todoSettings?.userId || this.state.taskDurationsActive} onClick={this.onAddButtonHandler} icon primary>
                  <Icon name="add" />
                    Add new Timebox setting
                </Button>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
        <TimeboxSettingAddModal
          mode={this.state.settingModalMode}
          currentLabelValue={this.state.currentModalLabelValue}
          currentDurationValue={this.state.currentModalDurationValue}
          currentColor={this.state.currentModalColor}
          options={this.createDropDownOptions(this.props.auth)}
          open={this.state.addSettingModalOpen}
          loading={this.state.loading}
          onCancel={this.onCancelButtonHandler}
          onSave={this.onSaveButtonHandler}
          onLabelChanged={this.onChangeLabelHandler}
          onDurationChanged={this.onChangeDurationHandler}
          onColorChanged={this.onChangeColorHandler}
          onNewLabel={this.onNewLabelHandler}
          onDropdownClicked={this.onLabelDropdownClicked}
          labelError={this.state.labelError}
          durationError={this.state.durationError}
        />
        <TimeboxSettingRemoveModal
          open={this.state.removeSettingModalOpen}
          onCancel={this.onCancelButtonHandler}
          onRemove={this.onRemoveLabelHandler}
          onCheckboxChanged={this.onDeleteTodoistLabelCheckboxHandler}
          label={this.state.labelToRemove}
          color={this.state.labelColorToRemove}
          loading={this.state.loading}
        />
      </Segment>
    )
  }
}



const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  }
}

export default connect(mapStateToProps, actions)(TimeBoxSettings)