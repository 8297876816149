import React, { Component } from "react"
import { Button, Modal, Input, } from 'semantic-ui-react'


class DeleteUserModal extends Component {

  state = {
    inputFieldValue: "",
    loading: false
  }

  onChangeInputFieldValue = (value) => {
    this.setState({
      inputFieldValue: value
    })
  }

  onCancel = () => {
    this.setState({
      inputFieldValue: ""
    })
    this.props.onCancel()
  }

  onDeleteUser = async () => {
    this.setState({
      loading: true
    })
    await this.props.onDeleteUser()
    this.setState({
      loading: false
    })
  }

  render() {
    return (
      <Modal size="tiny" open={this.props.open} onClose={this.props.onCancel}>
        <Modal.Header>
          Do you really want to delete your account?
        </Modal.Header>
        <Modal.Content>
          <div>
            This will permanently remove all data associated with your account. This is not reversible. Your active subscription will be canceled automatically. 
          </div>
          <div style={{ marginTop: "1em" }}>
            Type <b>delete</b> into this field to confirm that you want to delete your account.
          </div>
          <div>
            <Input value={this.state.inputFieldValue} onChange={(event) => this.onChangeInputFieldValue(event.target.value)} />
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button negative disabled={this.props.loading} onClick={this.onCancel}>No, cancel</Button>
          <Button positive disabled={this.props.loading || this.state.inputFieldValue.toLowerCase() !== "delete"} loading={this.props.loading} as="a" href="/api/delete/user">Yes, permanently delete my account</Button>
        </Modal.Actions>
      </Modal>
    )
  }
}

export default DeleteUserModal
