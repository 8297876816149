import React, { useState } from "react"
import { NavLink } from "react-router-dom"
import {
  Segment,
  Header,
  Button,
  Checkbox,
  Form
} from "semantic-ui-react"

const CookiePopup = ({ dispatch }) => {


  const [showPreferences, setShowPreferences] = useState(false)
  const [analyticalValue, setAnalyticalValue] = useState(true)

  const toggleAnalyticalValue = () => setAnalyticalValue(!analyticalValue)

  return (
    <Segment raised style={{ position: 'fixed', left: "3%", right: "3%", bottom: "2%", zIndex: 1000 }}>
      <Header>We use cookies</Header>
      <p>We use cookies and other tracking technologies to provide core features and to analyze website traffic. By using our website and service, you consent to the use of cookies and other tracking technologies. To learn more, see our <NavLink to="/privacy">Privacy Policy</NavLink></p>
      <Form>
        {showPreferences ?
          (
            <Form.Group style={{ marginBottom: "14px" }}>
              <Form.Field>
                <Checkbox checked disabled toggle label="Functional" />
              </Form.Field>
              <Form.Field>
                <Checkbox checked={analyticalValue} onChange={toggleAnalyticalValue} toggle label="Analytical" />
              </Form.Field>
            </Form.Group>
          ) : null
        }
        <Form.Field>
          <Button primary onClick={() => dispatch({ type: "accept", payload: analyticalValue })}>Accept</Button>
          <Button disabled={showPreferences} tertiary="true" onClick={() => setShowPreferences(true)}>Change preferences</Button>
        </Form.Field>
      </Form>

    </Segment >
  )
}

export default CookiePopup