import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Message, Button } from "semantic-ui-react"
import moment from "moment"
import { NavLink } from "react-router-dom"

export class SubscriptionWarning extends Component {
  render() {

    const subscriptionEnds = this.props.auth?.payment.validUntil

    const endsInDays = moment(subscriptionEnds).diff(moment(), "days")
    const endsInHours = moment(subscriptionEnds).diff(moment(), "hours")
    const hasEnded = moment(subscriptionEnds).isBefore(moment())
    const daysText = endsInDays === 1 ? "day" : "days"
    const hoursText = endsInHours === 1 ? "hour" : "hours"

    const endsInString = endsInDays >= 1 ? `${endsInDays} ${daysText}` : `${endsInHours} ${hoursText}`

    let messageText
    let warningOrErrorType

    if (this.props.auth?.payment.subscriptionStatus === "trial") {
      if (!hasEnded) {
        // Your free trial will end in xx days/hours ==> warning
        messageText = `Your free trial will end in ${endsInString}`
        warningOrErrorType = true
      } else {
        // Your free trial has ended ==> error
        messageText = `Your free trial has ended`
        warningOrErrorType = false
      }
    } else if (this.props.auth?.payment.subscriptionStatus === "active"
      || this.props.auth?.payment.subscriptionStatus === "canceled") {
      if (hasEnded) {
        // Your subscription has ended ==> error
        messageText = `Your subscription has ended`
        warningOrErrorType = false
      }
    } else if (this.props.auth?.payment.subscriptionStatus === "incomplete"
      || this.props.auth?.payment.subscriptionStatus === "incomplete_expired"
      || this.props.auth?.payment.subscriptionStatus === "past_due"
      || this.props.auth?.payment.subscriptionStatus === "unpaid") {
      if (hasEnded) {
        // Your subscription has ended ==> error
        messageText = `Your subscription is incomplete and has ended`
        warningOrErrorType = false
      } else {
        messageText = `Your subscription is incomplete and will end in ${endsInString}`
        warningOrErrorType = true
      }
    }

    const message =
      messageText ?
        <Message warning={warningOrErrorType} error={!warningOrErrorType}>
          <p>
            {messageText}
          </p>
          <Button primary as={NavLink} to="/profile">
            Upgrade now
          </Button>
        </Message>
        : null

    return (
      message
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  }
}

export default connect(mapStateToProps)(SubscriptionWarning)
