import React, { Component } from 'react';
import { Route, Routes, Navigate } from "react-router-dom"
import { connect } from "react-redux"
import { Loader } from "semantic-ui-react"
import './assets/main.css';
import * as actions from "./redux/actions"

import ResponsiveNavigationBar from "./containers/Navigation/ResponsiveNavigationBar"
import Footer from "./containers/Footer/Footer"
import Login from "./containers/Auth/Login"
import Signup from "./containers/Auth/Signup"
import Dashboard from "./containers/Dashboard/Dashboard"
import Profile from './containers/Profile/Profile';
import SubscriptionSuccess from './containers/Profile/SubscriptionSuccess';
import Privacy from "./containers/Footer/PrivacyPolicy"
import Terms from "./containers/Footer/Terms"
import Legal from "./containers/Footer/LegalNotice"
import Contact from "./containers/Footer/Contact"
import Changelog from "./containers/Changelog/Changelog"
import Documentation from "./containers/Documentation/Documentation"
import Home from "./containers/Home/Home"




class App extends Component {

  componentDidMount() {
    this.props.fetchUser()
    this.setupCrisp()
    // require("dotenv").config()
  }

  setupCrisp() {
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = "3cc036c1-ba62-4016-ae9d-d8bb94096fc5";

    (function () {
      var d = document;
      var s = d.createElement("script");

      s.src = "https://client.crisp.chat/l.js";
      s.async = 1;
      d.getElementsByTagName("head")[0].appendChild(s);
    })();
  }

  render() {



    return (
      <ResponsiveNavigationBar>
        <div style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
          <Routes>
            <Route path="/" exact="true" element={
              this.props.auth === null ?
                <Loader active size='medium'>Loading</Loader> :
                this.props.auth === false ?
                  <Navigate to="/home" /> :
                  <Navigate to="/dashboard" />
            }
            />
            <Route path="/home" exact="true" element={<Home />} />
            <Route path="/homepage" exact="true" element={<Navigate replace to="/home" />} />
            <Route path="/dashboard" exact="true" element={<Dashboard />} />
            <Route path="/profile" exact="true" element={<Profile />} />
            <Route path="/login" exact="true" element={<Login />} />
            <Route path="/signup" exact="true" element={<Signup />} />
            <Route path="/privacy" exact="true" element={<Privacy />} />
            <Route path="/Contact" exact="true" element={<Contact />} />
            <Route path="/legal" exact="true" element={<Legal />} />
            <Route path="/terms" exact="true" element={<Terms />} />
            <Route path="/changelog" exact="true" element={<Changelog />} />
            <Route path="/documentation" exact="true" element={<Documentation />} />
            <Route path="/subscriptionsuccess" exact="true" element={<SubscriptionSuccess />} />

          </Routes>
          <Footer />
        </div>
      </ResponsiveNavigationBar>
      // </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  }
}

export default connect(mapStateToProps, actions)(App);
