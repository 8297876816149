import React, { Component } from 'react'
// import ReactMarkdown from "react-markdown"
import CommonMark from "commonmark"
import ReactRenderer from "commonmark-react-renderer"
import ChangelogPath from "../../assets/changelog.md"
import {
  Container,
  List,
  Header
} from "semantic-ui-react"


export default class Changelog extends Component {
  constructor(props) {
    super(props)

    this.state = { changelog: "" }
  }

  componentWillMount() {
    fetch(ChangelogPath).then((response) => response.text()).then((text) => {
      this.setState({ changelog: text })
    })
  }


  render() {

    const parser = new CommonMark.Parser()
    const renderer = new ReactRenderer({
      renderers: {
        item: function (props) { return <List.Item>{props.children}</List.Item> },
        list: function (props) { return <List bulleted>{props.children}</List> },
        heading: function (props) { return <Header as={"h" + props.level}>{props.children}</Header> }
      }
    })
    var node = parser.parse(this.state.changelog)
    var result = renderer.render(node)

    return (
      <Container text style={{ marginTop: "7em" }}>
        {result}
      </Container>
    )
  }
}


