import React, { Component } from "react"

import {
  Container,
  Card,
  Button,
  Item,
  Image,
} from "semantic-ui-react"
import { NavLink } from "react-router-dom"

import classes from "./Style.module.css"

class Login extends Component {
  render() {
    return (
      <Container text style={{ marginTop: "7em" }}>
        <Card.Group centered>
          <Card raised>
            <Card.Content>
              <Card.Header>Log in</Card.Header>
            </Card.Content>
            <Card.Content>
              <Item.Group>
                <Item>
                  <Button as="a" href="/auth/google" basic fluid icon="/g-logo.png">
                    <Image src="/g-logo.png" width={18} height={18} floated="left" />
                    Log in with Google
                  </Button>
                </Item>
                <Item>
                  <Button as="a" href="/auth/facebook" basic fluid >
                    <Image src="/f-logo.png" width={18} height={18} floated="left" />
                    Log in with Facebook
                  </Button>
                </Item>
              </Item.Group>
              {/* <Divider horizontal>Or</Divider>
              <p>
              <Label color="orange" pointing="below">
                Coming soon...
              </Label>
                <Input disabled fluid icon placeholder='E-Mail'>
                  <input />
                  <Icon name='at' />
                </Input>
              </p>
              <p>
                <Input disabled type="password" fluid icon placeholder="Password">
                  <input />
                  <Icon name="lock" />
                </Input>
              </p> */}
              <Item.Group>
                {/* <Item>
                  <Button disabled primary fluid>Log in</Button>
                </Item>
                <p className={classes.text}>
                  <NavLink to="/#">Forgot password?</NavLink>
                </p> */}
                <p className={classes.text}>
                  By logging in with Google or Facebook you accept tascaly's
                  <NavLink to="/terms">
                    Terms and Conditions
                  </NavLink>
                  {" "}and{" "}
                  <NavLink to="/privacy">
                    Privacy Policy
                  </NavLink>.
                </p>
              </Item.Group>
            </Card.Content>
            <Card.Content textAlign="center">
              <p className={classes.switch}>
                Don't have an account yet?{" "}
                <NavLink to="/signup">
                  Sign up
                </NavLink>
              </p>
            </Card.Content>
          </Card>
        </Card.Group>
      </Container>
    )
  }
}

export default Login