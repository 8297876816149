import React, { Component } from "react"
import { NavLink } from "react-router-dom"
import {
  Container,
  Icon,
  Menu,
  Sidebar,
  Image
} from "semantic-ui-react"
import AuthButtons from "./AuthButtons"
import NavigationMenuItems from "./NavigationMenuItems"

export default class MobileNavigationBar extends Component {
  state = {}

  handleSidebarHide = () => this.setState({ sidebarOpened: false })

  handleToggle = () => this.setState({ sidebarOpened: true })

  render() {
    const { children } = this.props
    const { sidebarOpened } = this.state

    return (
        <Sidebar.Pushable>
          <Sidebar
            as={Menu}
            animation="overlay"
            inverted
            onHide={this.handleSidebarHide}
            vertical
            borderless
            visible={sidebarOpened}
          >

            <Menu.Item onClick={this.handleSidebarHide} as={NavLink} to="/" exact>
              Home
            </Menu.Item>
            <NavigationMenuItems showMenu={this.props.showMenu} onClick={this.handleSidebarHide} />
          </Sidebar>

          <Sidebar.Pusher dimmed={sidebarOpened} >
            <Container fixed="top">
              <Menu fixed="top" inverted borderless>
                <Menu.Item onClick={this.handleToggle}>
                  <Icon name="sidebar" />
                </Menu.Item>
                <Menu.Item as={NavLink} to="/" exact="true">
                  <Image size="tiny" src="tascaly_logo.png" />
                </Menu.Item>
                <AuthButtons />
              </Menu>
            </Container>

            {children}
          </Sidebar.Pusher>
        </Sidebar.Pushable>
    )
  }
}