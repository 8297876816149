import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import { Form, Dropdown, Segment, Icon, Message, Button, Checkbox, Divider, Label } from "semantic-ui-react"
import { colors as googleColors } from "./GoogleColorMappings"
import { colors as microsoftColors } from "./MicrosoftColorMappings"

import * as actions from "../../redux/actions"
import ReminderSettings from "./ReminderSettings"

class CalendarSettings extends Component {

  state = {
    calendars: [],
    colors: Object.values(googleColors).map(color => {
      return (
        { key: color.id, text: (<span>{(color.hexCode ? (<Icon name="circle" style={{ color: color.hexCode }} />) : null)}{" "}{color.name}</span>), value: color.name }
      )
    }),
    categories: [],
    selectedCalendar: null,
    selectedColor: null,
    selectedCategory: null,
    loadingCalendar: false,
    loadingColor: false,
    loadingCategory: false,
    showCalendarWarning: false,
    private: false,
    onPrivateLoading: false,
    todoistLinkDescription: false,
    onTodoistLinkDescriptionLoading: false
  }

  static getDerivedStateFromProps(props, state) {
    if (props.auth?.calendarSettings?.calendars?.length !== state.calendars?.length
      || props.auth?.calendarSettings?.categories?.length !== state.categories?.length) {
      const calendars = props.auth?.calendarSettings?.calendars?.map(calendar => {
        let color
        if (props.auth.calendarSettings.provider === "Google Calendar") {
          color = calendar.color
        } else if (props.auth.calendarSettings.provider === "Microsoft") {
          color = microsoftColors[calendar.color]
        }
        return (
          { key: calendar.id, text: (<span><Icon name="calendar" style={{ color: color }} />{" "}{calendar.name}</span>), value: calendar.name }
        )
      })

      const categories = props.auth?.calendarSettings?.categories?.map(category => {
        return (
          { key: category.id, text: (<span><Icon name="circle" style={{ color: microsoftColors[category.color] }} />{" "}{category.displayName}</span>), value: category.displayName }
        )
      })
      if (props.auth?.calendarSettings?.categories) {
        categories.splice(0, 0, { key: null, text: "No category", value: "No category" })
      }

      if (!calendars) {
        return {
          calendars: [],
          colors: [],
          categories: [],
          selectedCalendar: null,
          selectedColor: null,
          selectedCategory: null
        }
      }

      const calendarId = props.auth?.calendarSettings?.calendarId
      const categoryId = props.auth?.calendarSettings?.category
      let colorId
      if (calendarId) {
        colorId = props.auth?.calendarSettings?.color || ""
      } else {
        colorId = props.auth?.calendarSettings?.color

      }

      return {
        calendars: calendars,
        categories: categories,
        selectedCalendar: calendars.find(calendar => calendar.key === calendarId)?.value,
        selectedColor: state.colors.find(color => color.key === colorId)?.value,
        selectedCategory: categories.find(category => category.key === categoryId)?.value,
        private: props.auth?.calendarSettings?.private,
        todoistLinkDescription: props.auth?.calendarSettings?.todoistLinkDescription,
      }
    }
    return null
  }

  onChangeCalendar = async (event, calendar) => {
    this.setState({ loadingCalendar: true })

    const value = calendar.value
    const { key } = calendar.options.find(option => option.value === value)

    await this.props.setCalendar(key)

    let showCalendarWarning = false
    if (this.props.auth?.calendarSettings?.provider === "Google Calendar" && this.state.selectedColor === "Default Calendar Color") {
      showCalendarWarning = true
    } else if (this.props.auth?.calendarSettings?.provider === "Microsoft" && this.state.selectedCategory === "No category") {
      showCalendarWarning = true
    }

    this.setState({
      selectedCalendar: value,
      loadingCalendar: false,
      showCalendarWarning: showCalendarWarning
    })
  }

  onChangeColor = async (event, color) => {
    this.setState({ loadingColor: true })
    const value = color.value
    const { key } = color.options.find(option => option.value === value)


    await this.props.setEventColor(key)


    let showCalendarWarning = false
    if (value === "Default Calendar Color") {
      showCalendarWarning = true
    }

    this.setState({
      selectedColor: value,
      loadingColor: false,
      showCalendarWarning: showCalendarWarning
    })
  }

  onChangeCategory = async (event, category) => {
    this.setState({ loadingCategory: true })
    const value = category.value
    const { key } = category.options.find(option => option.value === value)

    await this.props.setCategory(key)


    let showCalendarWarning = false
    if (value === "No category") {
      showCalendarWarning = true
    }

    this.setState({
      selectedCategory: value,
      loadingCategory: false,
      showCalendarWarning: showCalendarWarning
    })
  }

  onAcceptCalendarWarning = () => {
    this.setState({ showCalendarWarning: false })
  }

  onChangePrivate = async (event, privateSetting) => {
    this.setState({ onPrivateLoading: true })
    await this.props.setPrivateSetting(privateSetting.checked)
    this.setState({
      onPrivateLoading: false,
      private: privateSetting.checked
    })
  }

  onChangeTodoistLinkDescription = async (event, todoistLinkDescriptionSetting) => {
    this.setState({ onTodoistLinkDescriptionLoading: true })
    await this.props.setTodoistLinkDescriptionSetting(todoistLinkDescriptionSetting.checked)
    this.setState({
      onTodoistLinkDescriptionLoading: false,
      todoistLinkDescription: todoistLinkDescriptionSetting.checked
    })
  }

  getTimezoneOfCalendar = () => {
    let calendar
    if (this.props.auth?.calendarSettings?.provider === "Google Calendar") {
      calendar = this.props.auth.calendarSettings.calendars.find(calendar => calendar.id === this.props.auth.calendarSettings.calendarId)
      if (calendar) {
        return calendar.timeZone
      }
    } else if (this.props.auth?.calendarSettings?.provider === "Microsoft") {

    }
  }

  render() {

    let colorCategorySelector = null

    colorCategorySelector = (
      this.props.auth?.calendarSettings?.provider === "Google Calendar" ?
        <Form.Input
          label="Event color"
          width={8}
        >
          <Dropdown
            options={this.state.colors}
            placeholder="Select a color"
            search
            selection
            fluid
            value={this.state.selectedColor}
            loading={this.state.loadingColor}
            disabled={this.state.loadingColor}
            onChange={this.onChangeColor}
          />
        </Form.Input>
        :
        <Form.Input
          label="Category"
          width={8}
        >
          <Dropdown
            options={this.state.categories}
            placeholder="Select a category"
            search
            selection
            fluid
            value={this.state.selectedCategory}
            loading={this.state.loadingCategory}
            disabled={this.state.loadingCategory || !this.props.auth.calendarSettings}
            onChange={this.onChangeCategory}
          />
        </Form.Input>
    )

    return (
      <Fragment>
        <Segment raised>
          <Form>
            <Form.Group>
              <Form.Input
                label="Calendar"
                width={8}
              >
                <Dropdown
                  options={this.state.calendars}
                  placeholder="Select a calendar"
                  search
                  selection
                  fluid
                  value={this.state.selectedCalendar}
                  loading={this.state.loadingCalendar}
                  disabled={this.state.loadingCalendar || !this.props.auth.calendarSettings} 
                  onChange={this.onChangeCalendar}
                />
              </Form.Input>
              {/* <Form.Input
              label="Timezone"
              width={3}
            >
              <Label size="big">
                {this.getTimezoneOfCalendar()}
                </Label>
            </Form.Input> */}
              {colorCategorySelector}
            </Form.Group>
          </Form>
          <Message
            warning
            hidden={!this.state.showCalendarWarning || !this.props.auth?.calendarSettings}>
            <Message.Header>You have chosen the standard {this.props.auth?.calendarSettings?.provider === "Microsoft" ? "category" : "color"} </Message.Header>
            This is strongly discouraged if you have chosen your main calendar. This may cause all your previous calendar events to suddenly be created as tasks.
            <p>
              <Button secondary onClick={this.onAcceptCalendarWarning}>Ok, I understand</Button>
            </p>
          </Message>
        </Segment>
        <Segment raised>
          <Form>
            <Form.Group>
              <Form.Input
                width="8">
                <Checkbox
                  label="Create calendar events with private visibility "
                  checked={this.state.private}
                  onChange={this.onChangePrivate}
                  disabled={this.state.onPrivateLoading || !this.props.auth.calendarSettings}
                />
              </Form.Input>
            </Form.Group>
          </Form>
          <Divider />
          <Form>
            <Form.Group>
              <Form.Input
                width="16">
                <Checkbox
                  label="Add a link to the Todoist task to the event description"
                  checked={this.state.todoistLinkDescription}
                  onChange={this.onChangeTodoistLinkDescription}
                  disabled={this.state.onTodoistLinkDescriptionLoading || !this.props.auth.calendarSettings}
                />
              </Form.Input>
            </Form.Group>
          </Form>
          <Label basic color="grey" pointing="above">
            Warning: This will override any existing event description!
          </Label>
        </Segment>
        <ReminderSettings />
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  }
}

export default connect(mapStateToProps, actions)(CalendarSettings)