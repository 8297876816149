import React, { Component } from "react"
import {
  Container, Grid, Header, Loader, Message, Segment
} from "semantic-ui-react"
import { connect } from "react-redux"
import IntegrationContainer from "../Integrations/IntegrationContainer"
import TimeBoxSettings from "../TimeBoxSettings/TimeBoxSettings"
import CalendarSettings from "../CalendarSettings/CalendarSettings"
import AdvancedSettings from "../AdvancedSettings/AdvancedSettings"
import WelcomeWizard from "../WelcomeWizard/WelcomeWizard"
import Status from "../Status/Status"
import "react-semantic-toasts/styles/react-semantic-alert.css";
import SubscriptionWarning from "../Status/SubscriptionWarning"
import TaskDurationSettings from "../TaskDurationSettings/TaskDurationSettings"

class Dashboard extends Component {
  render() {

    window.gtag('event', 'conversion', {'send_to': 'AW-615065118/jQN-CL_zypkDEJ7MpKUC'});

    let dashboard
    if (this.props.auth === null) {
      dashboard = (
        <Segment padded="very" basic>
          <Loader active size='medium'>Loading</Loader>
        </Segment>
      )
    } else if (this.props.auth === false) {
      dashboard = (
        <Message floating negative>
          <Message.Header>You are not logged in</Message.Header>
          <p>Please log in to access your account</p>
        </Message>
      )
    } else {
      dashboard = (
        <Grid columns="equal" container divided="vertically" stackable>
          <Grid.Row>
            <Grid.Column mobile={8} tablet={16} computer={3} >
              <Header>Status</Header>
            </Grid.Column>
            <Grid.Column mobile={8} tablet={16} computer={13} >
              <Status />
              <SubscriptionWarning />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column mobile={8} tablet={16} computer={3} >
              <Header>Integrations</Header>
            </Grid.Column>
            <Grid.Column mobile={8} tablet={16} computer={13} >
              <IntegrationContainer />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column mobile={8} tablet={16} computer={3} >
              <Header>Time Box Settings</Header>
            </Grid.Column>
            <Grid.Column mobile={8} tablet={16} computer={13} >
              <TaskDurationSettings />
              <TimeBoxSettings />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column mobile={8} tablet={16} computer={3} >
              <Header>Calendar Settings</Header>
            </Grid.Column>
            <Grid.Column mobile={8} tablet={16} computer={13} >
              <CalendarSettings />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column mobile={8} tablet={16} computer={3} >
              <Header>Advanced Settings</Header>
            </Grid.Column>
            <Grid.Column mobile={8} tablet={16} computer={13} >
              <AdvancedSettings />
            </Grid.Column>
          </Grid.Row>
        </Grid>

      )
    }

    return (
      <Container style={{ marginTop: "7em" }}>
        {dashboard}
        {this.props.auth?.welcomeWizardSeen ? null : <WelcomeWizard /> }
      </Container >
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  }
}

export default connect(mapStateToProps)(Dashboard)
