import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Dropdown, Segment } from "semantic-ui-react";

import * as actions from "../../redux/actions";

class ReminderSettings extends Component {
  state = {
    reminderOptions: [
      {
        key: "none",
        text: "No reminder",
        value: "No reminder",
      },
      {
        key: "0",
        text: "0 minutes",
        value: "0 minutes",
      },
      {
        key: "15",
        text: "15 minutes",
        value: "15 minutes",
      },
      {
        key: "30",
        text: "30 minutes",
        value: "30 minutes",
      },
      {
        key: "60",
        text: "1 hour",
        value: "1 hour",
      },
      {
        key: "120",
        text: "2 hours",
        value: "2 hours",
      },
    ],
    reminderLoading: false,
    selectedReminder: "",
  };

  static getDerivedStateFromProps(props, state) {
    if (props.auth?.calendarSettings?.reminder) {
      return ({
        selectedReminder: state.reminderOptions.find(option => option.key === props.auth?.calendarSettings?.reminder)?.value
      })
    }
    return null;
  }

  onChangeReminder = async (event, reminder) => {
    this.setState({ reminderLoading: true });
    const value = reminder.value;
    const { key } = reminder.options.find((option) => option.value === value);

    await this.props.setReminder(key);

    this.setState({
      selectedReminder: value,
      reminderLoading: false,
    }); 
  };

  render() {
    return (
      <Segment raised>
        <Form>
          <Form.Group>
            <Form.Input label="Event reminder" width="8">
              <Dropdown
                options={this.state.reminderOptions}
                value={this.state.selectedReminder}
                loading={this.state.reminderLoading}
                disabled={this.state.reminderLoading || !this.props.auth.calendarSettings}
                selection
                fluid
                onChange={this.onChangeReminder}
              />
            </Form.Input>
          </Form.Group>
        </Form>
      </Segment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, actions)(ReminderSettings);
