import React from "react"
import { Label } from "semantic-ui-react"

function VersionLabel() {
  return (
    <Label circular color={"grey"} size="mini">V 1.6.0</Label>
  )
}

export default VersionLabel
