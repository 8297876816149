import React, { Component } from "react"
// import { withRouter } from "react-router";
import { useLocation } from "react-router-dom"
import { connect } from "react-redux"
import {
  Card,
} from "semantic-ui-react"
import { SemanticToastContainer, toast } from "react-semantic-toasts";
import "react-semantic-toasts/styles/react-semantic-alert.css";
import qs from "qs"
import * as actions from "../../redux/actions"
import IntegrationCard from "./IntegrationCard"
import ConfirmationModal from "../UI/ConfirmationModal"
import CalendarSelector from "./CalendarSelector"
import calendarIcons from "../../utils/calendarIcons"

class IntegrationContainer extends Component {

  state = {
    modalOpen: false,
    providerToDisconnect: "",
    loading: false,
    calendarModalOpen: false
  }

  componentDidMount() {
    const queryString = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).connected
    // TODO Abfrage mit möglichen Integrations abgleichen
    if (queryString) {
      setTimeout(() => {
        toast({
          type: "success",
          icon: "linkify",
          title: "Congratulations!",
          description: "You successfully conncted your " + queryString + " account",
          animation: "fly left",
          time: 4000,
        })
      }, 500);
    }
  }
  
  render() {
    return (
      <React.Fragment>
        <Card.Group itemsPerRow={2} stackable>
          <IntegrationCard
            provider="Todoist"
            connected={this.isTodoConnected()}
            connectLink="/auth/todoist"
            disconnectAction={this.handleDisconnectButton}
            additionalState={this.getTodoistAccountType()}
            logo="/todoist.png"
          />
          <IntegrationCard
            provider={this.getCalendarProvider()}
            connected={this.isCalendarConnected()}
            connectButton={this.openCalendarModal}
            disconnectAction={this.handleDisconnectButton}
            logo={calendarIcons[this.getCalendarProvider()]}
          />
        </Card.Group>
        <ConfirmationModal
          open={this.state.modalOpen}
          loading={this.state.loading}
          closeAction={this.closeModal}
          confirmAction={this.removeIntegration}
          header={"Do you really want to remove your " + this.state.providerToDisconnect + " integration?"}
          message="All your synchronisations will stop working."
          yesText="Yes"
          noText="No, cancel"
        />
        <CalendarSelector
          open={this.state.calendarModalOpen}
          closeAction={this.closeCalendarModal}
        />
        <SemanticToastContainer
          position="top-right"
          animation="fly right"
          className="floating"
        />
      </React.Fragment>
    )
  }

  handleDisconnectButton = (provider) => {
    this.setState({ modalOpen: true, providerToDisconnect: provider })
  }

  closeModal = () => {
    this.setState({ modalOpen: false, providerToDisconnect: "", loading: false })
  }

  closeCalendarModal = () => {
    this.setState({ calendarModalOpen: false })
  }

  openCalendarModal = () => {
    this.setState({ calendarModalOpen: true })
  }

  removeIntegration = async () => {
    const provider = this.state.providerToDisconnect
    this.setState({ ...this.state, loading: true })
    await this.props.revokeAccess(provider)
    this.closeModal()
    setTimeout(() => {
      toast({
        type: "info",
        icon: "unlink",
        title: "Integration removed",
        description: "You removed your " + provider + " integration",
        animation: "fly left",
        time: 4000,
      });
    }, 500)
  }

  isTodoConnected = () => {
    let todoConnected = false
    if (this.props.auth?.todoSettings?.provider === "Todoist" && this.props.auth?.todoSettings?.token) {
      todoConnected = true
    }
    return todoConnected
  }

  isCalendarConnected = () => {
    let calendarConnected = false
    if (this.props.auth?.calendarSettings?.token) {
      calendarConnected = true
    }
    return calendarConnected
  }

  getCalendarProvider = () => {
    let calendarProvider = null
    if (this.props.auth?.calendarSettings?.token) {
      calendarProvider = this.props.auth.calendarSettings.provider
    } else {
      calendarProvider = "Calendar"
    }
    return calendarProvider
  }

  getTodoistAccountType = () => {
    let todoistAccountType = null
    if (this.props.auth?.todoSettings?.provider === "Todoist" && this.props.auth?.todoSettings?.accountType) {
      todoistAccountType = this.props.auth.todoSettings.accountType
    }
    return todoistAccountType
  }

}
const mapStateToProps = (state) => {
  return {
    auth: state.auth
  }
}

const withLocation = Component => props => {
  const location = useLocation();

  return <Component {...props} location={location} />;
};
export default connect(mapStateToProps, actions)(withLocation(IntegrationContainer))
// export default connect(mapStateToProps, actions)(withRouter(IntegrationContainer))