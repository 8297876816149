// export const colors = {
//   30: { hexCode: "#b8255f", name: "Berry Red" },
//   31: { hexCode: "#db4035", name: "Red" },
//   32: { hexCode: "#ff9933", name: "Orange" },
//   33: { hexCode: "#fad000", name: "Yellow" },
//   34: { hexCode: "#afb83b", name: "Olive Green" },
//   35: { hexCode: "#7ecc49", name: "Lime Green" },
//   36: { hexCode: "#299438", name: "Green" },
//   37: { hexCode: "#6accbc", name: "Mint Green" },
//   38: { hexCode: "#158fad", name: "Teal" },
//   39: { hexCode: "#14aaf5", name: "Sky Blue" },
//   40: { hexCode: "#96c3eb", name: "Light Blue" },
//   41: { hexCode: "#4073ff", name: "Blue" },
//   42: { hexCode: "#884dff", name: "Grape" },
//   43: { hexCode: "#af38eb", name: "Violet" },
//   44: { hexCode: "#eb96eb", name: "Lavender" },
//   45: { hexCode: "#e05194", name: "Magenta" },
//   46: { hexCode: "#ff8d85", name: "Salmon" },
//   47: { hexCode: "#808080", name: "Charcoal" },
//   48: { hexCode: "#b8b8b8", name: "Grey" },
//   49: { hexCode: "#ccac93", name: "Taupe" }
// }

export const colors = {
  "berry_red": { hexCode: "#b8255f", name: "Berry Red" },
  "red": { hexCode: "#db4035", name: "Red" },
  "orange": { hexCode: "#ff9933", name: "Orange" },
  "yellow": { hexCode: "#fad000", name: "Yellow" },
  "olive_green": { hexCode: "#afb83b", name: "Olive Green" },
  "lime_green": { hexCode: "#7ecc49", name: "Lime Green" },
  "green": { hexCode: "#299438", name: "Green" },
  "mint_green": { hexCode: "#6accbc", name: "Mint Green" },
  "teal": { hexCode: "#158fad", name: "Teal" },
  "sky_blue": { hexCode: "#14aaf5", name: "Sky Blue" },
  "light_blue": { hexCode: "#96c3eb", name: "Light Blue" },
  "blue": { hexCode: "#4073ff", name: "Blue" },
  "grape": { hexCode: "#884dff", name: "Grape" },
  "violet": { hexCode: "#af38eb", name: "Violet" },
  "lavender": { hexCode: "#eb96eb", name: "Lavender" },
  "magenta": { hexCode: "#e05194", name: "Magenta" },
  "salmon": { hexCode: "#ff8d85", name: "Salmon" },
  "charcoal": { hexCode: "#808080", name: "Charcoal" },
  "grey": { hexCode: "#b8b8b8", name: "Grey" },
  "taupe": { hexCode: "#ccac93", name: "Taupe" }
}