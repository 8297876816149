import React from "react"
import { List } from "semantic-ui-react"

export default function StatusIcon(props) {
  let listIcon
  if (props.ok) {
    if (props.warn) {
      listIcon = <List.Icon name="warning circle" size="large" color="yellow" verticalAlign="middle" />
    } else {
      listIcon = <List.Icon name="check circle" size="large" color="green" verticalAlign="middle" />
    }
  } else {
    listIcon = <List.Icon name="times circle" size="large" color="red" verticalAlign="middle" />

  }

  return listIcon
}
