import React from 'react'
import { List } from "semantic-ui-react"

function CalendarListDescription(props) {
  let text
  if (props.connected) {
    text = "Connected"
    if (props.calendarId) {
      text = text + ", calendar selected"
    } else {
      text = text + ", no calendar selected yet!"
    }
  } else {
    text = "Not connected"
  }
  return (
    <List.Description>{text}</List.Description>
  )
}

export default CalendarListDescription

