import React, { Component } from "react"
import { Header, Segment, Button, Icon, Grid, Image, Container, Divider } from "semantic-ui-react"
import { NavLink } from "react-router-dom"
import PricingCard from "./PricingCard"

export default class Home extends Component {
  render() {
    return (
      <React.Fragment>
        <Segment
          inverted
          style={{ minHeight: 500, padding: '1em 0em' }}
          vertical
        >
          <Container>
            <Grid stackable columns={2}>
              <Grid.Column width={9}>
                <Container>
                  <Header
                    as="h1"
                    content="Estimate. Schedule. Complete."
                    inverted
                    style={{
                      fontSize: this.props.mobile ? "2em" : "4em",
                      fontWeight: "normal",
                      marginBottom: 0,
                      marginTop: this.props.mobile ? "1.5em" : "3em",
                    }}
                  />
                  <Header
                    as="h2"
                    content="Timebox your Todoist Tasks into your Calendar"
                    inverted
                    style={{
                      fontSize: this.props.mobile ? '1.5em' : '1.7em',
                      fontWeight: 'normal',
                    }}
                  />
                  <Container style={{ marginTop: "2em" }}>
                    <Button primary size='huge' as={NavLink} to="/signup">
                      Get Started
                      <Icon name='right arrow' />
                    </Button>
                  </Container>
                </Container>
              </Grid.Column>
              <Grid.Column width={7}>
                <Container style={{ marginTop: "8em" }}>
                  <Image size="large" src="tascaly_hero.svg" rounded />
                </Container>
              </Grid.Column>
            </Grid>
          </Container>
        </Segment>
        <Container style={{ marginTop: "6em" }}>
          <div id="howitworks" style={{
            display: "block",
            position: "relative",
            top: "-100px",
            visibility: "hidden"
          }} />
          <Grid stackable columns={2}>
            <Grid.Column>
              <Image size="big" src="home_setup.gif" />
            </Grid.Column>
            <Grid.Column>
              <Header as="h1"
                style={{
                  fontSize: this.props.mobile ? "2em" : "4em",
                  fontWeight: "normal",
                  marginBottom: 0,
                }}>
                Set up Timeboxes
              </Header>
              <Header as="h2"
                style={{
                  fontSize: this.props.mobile ? '1.5em' : '1.7em',
                  fontWeight: 'normal',
                  marginTop: this.props.mobile ? '0.5em' : '1.5em',
                }}>
                Create a mapping between Todoist labels and event durations.
              </Header>
              <Header as="h2"
                style={{
                  fontSize: this.props.mobile ? '1.5em' : '1.7em',
                  fontWeight: 'normal',
                  marginTop: this.props.mobile ? '0.5em' : '1.5em',
                }}>
                🔥 New: Use Todoist's task duration as an alternative to labels.
              </Header>
              <Container style={{ marginTop: "3em" }}>
              </Container>
            </Grid.Column>
          </Grid>
          <Divider style={{ marginTop: "6em" }} />
        </Container >
        <Container style={{ marginTop: "6em" }}>
          <Grid stackable columns={2}>
            <Grid.Column>
              <Header as="h1"
                style={{
                  fontSize: this.props.mobile ? "2em" : "4em",
                  fontWeight: "normal",
                  marginBottom: 0,
                }}>
                Estimate duration and label your Todoist tasks
              </Header>
              <Header as="h2"
                style={{
                  fontSize: this.props.mobile ? '1.5em' : '1.7em',
                  fontWeight: 'normal',
                  marginTop: this.props.mobile ? '0.5em' : '1.5em',
                }}>
                Your Todoist tasks with the right duration on the right time. But only the ones you choose.
              </Header>
            </Grid.Column>
            <Grid.Column>
              <Image size="big" src="home_timebox.gif" />
            </Grid.Column>
          </Grid>
          <Divider style={{ marginTop: "6em" }} />
        </Container >
        <Container style={{ marginTop: "6em" }}>
          <Grid stackable columns={2}>
            <Grid.Column>
              <Image size="big" src="home_twowaysync.gif" />
            </Grid.Column>
            <Grid.Column>
              <Header as="h1"
                style={{
                  fontSize: this.props.mobile ? "2em" : "4em",
                  fontWeight: "normal",
                  marginBottom: 0,
                }}>
                Create Todoist tasks from new calendar events
              </Header>
              <Header as="h2"
                style={{
                  fontSize: this.props.mobile ? '1.5em' : '1.7em',
                  fontWeight: 'normal',
                  marginTop: this.props.mobile ? '0.5em' : '1.5em',
                }}>
                Two-way-sync between your Google or Microsoft Office 365 calendar
              </Header>
              <Container style={{ marginTop: "3em" }}>
              </Container>
            </Grid.Column>
          </Grid>
        </Container >
        <Segment
          inverted
        >
          <Container style={{ marginTop: "2em", marginBottom: "2em" }}>
            <Grid stackable columns={4}>
              <Grid.Column>
                <Container textAlign="center">
                  <Icon name='calendar alternate' size='huge' />
                  <Header inverted as="h2" style={{ marginBottom: "1em" }}>Calendar providers</Header>
                  Works with Google and Microsoft Office 365 calendar.
                </Container>
              </Grid.Column>
              <Grid.Column>
                <Container textAlign="center">
                  <Icon name='sync alternate' size='huge' />
                  <Header inverted as="h2" style={{ marginBottom: "1em" }}>2-way-sync</Header>
                  Almost instant and full sync between Todoist and your calendar.
                </Container>
              </Grid.Column>
              <Grid.Column>
                <Container textAlign="center">
                  <Icon name='setting' size='huge' />
                  <Header inverted as="h2" style={{ marginBottom: "1em" }}>Customizable</Header>
                  Set up dedicated calendars, colors or categories.
                </Container>
              </Grid.Column>
              <Grid.Column>
                <Container textAlign="center">
                  <Icon name='lock' size='huge' />
                  <Header inverted as="h2" style={{ marginBottom: "1em" }}>Respects your privacy.</Header>
                  Doesn't read or save task and event contents.
                </Container>
              </Grid.Column>
            </Grid>
          </Container >
        </Segment>
        <Container textAlign="center" style={{ marginTop: "3em", marginBottom: "2em" }}>
          <div id="pricing" style={{
            display: "block",
            position: "relative",
            top: "-50px",
            visibility: "hidden"
          }} />
          <Header as="h1"
            style={{
              fontSize: this.props.mobile ? "2em" : "4em",
              fontWeight: "normal",
              marginBottom: 0,
            }}>
            Simple and easy pricing!
          </Header>
        </Container>
        <Container>

          <Grid columns={3} stackable >
            <Grid.Column>
              <PricingCard header="Free trial" price="14 days" period="free trial phase" />
            </Grid.Column>
            <Grid.Column>
              <PricingCard header="Monthly subscription" price="3$" period="per month" />
            </Grid.Column>
            <Grid.Column>
              <PricingCard header="Yearly subscription" price="24$" period="per year" save="save 33%" />
            </Grid.Column>
          </Grid>
        </Container>
      </React.Fragment >
    )
  }
}
