import React, { Component } from "react"
import { Form, Dropdown, Segment, Checkbox } from "semantic-ui-react"
import { connect } from "react-redux"
import * as actions from "../../redux/actions"

class AdvancedSettings extends Component {

  state = {
    onComplete: "",
    onPreComplete: false,
    onCompleteLoading: false,
    onPreCompleteLoading: false,
    completingOptions: [
      {
        key: "nothing",
        text: "Do nothing",
        value: "Do nothing"
      },
      {
        key: "checkmark",
        text: "Mark calendar event with a checkmark ✅",
        value: "Mark calendar event with a checkmark ✅"
      },
      {
        key: "remove",
        text: "Remove calendar event",
        value: "Remove calendar event"
      },
    ]
  }

  static getDerivedStateFromProps(props, state) {
    console.log("selected onComplete: " + state.completingOptions.find(option => option.key === props.auth?.todoSettings?.onComplete)?.value)
    if (props.auth?.todoSettings?.onComplete) {
      return ({
        onComplete: state.completingOptions.find(option => option.key === props.auth?.todoSettings?.onComplete)?.value,
        onPreComplete: props.auth?.todoSettings?.onPreComplete,
      })
    }
    return null
  }

  onChangeOnComplete = async (event, onCompleteSetting) => {
    this.setState({ onCompleteLoading: true })
    const value = onCompleteSetting.value
    const { key } = onCompleteSetting.options.find(option => option.value === value)
    console.log("key: " + key + " value: " + value)

    await this.props.setOnCompleteSetting(key)

    this.setState({
      onComplete: value,
      onCompleteLoading: false
    })
  }

  onChangeOnPreComplete = async (event, onPreCompleteSetting) => {
    this.setState({ onPreCompleteLoading: true })
    await this.props.setOnPreCompleteSetting(onPreCompleteSetting.checked)
    this.setState({
      onPreCompleteLoading: false,
      onPreComplete: onPreCompleteSetting.checked
    })
  }


  render() {
    return (
      <Segment raised>
        <Form>
          <Form.Group>
            <Form.Input
              label="When completing a Todoist task"
              width="8">
              <Dropdown
                options={this.state.completingOptions}
                value={this.state.onComplete}
                loading={this.state.onCompleteLoading}
                disabled={this.state.onCompleteLoading}
                selection
                fluid
                onChange={this.onChangeOnComplete} />
            </Form.Input>
          </Form.Group>
          <Form.Group>
            <Form.Input
              width="8">
              <Checkbox
                label="Remove calendar event when Todoist task is completed before event started"
                checked={this.state.onPreComplete}
                onChange={this.onChangeOnPreComplete}
                disabled={this.state.onPreCompleteLoading}
              />
            </Form.Input>
          </Form.Group>
        </Form>
      </Segment>
    )
  }



}


const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  }
}

export default connect(mapStateToProps, actions)(AdvancedSettings)