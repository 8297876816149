export const colors = {
  // Calendars:
  auto: "#0078D4",
  lightBlue: "#0078D4",
  lightTeal: "#038387",
  lightGreen: "#498205",
  lightPink: "#E3008C",
  lightOrange: "#DA3B01",
  lightRed: "#C50F1F",
  lightGray: "#69797E",
  // Categories:
  preset0: "#E74856",
  preset1: "#FF8C00",
  preset2: "#FFAB45",
  preset3: "#FFF100",
  preset4: "#47D041",
  preset5: "#30C6CC",
  preset6: "#73AA24",
  preset7: "#00BCF2",
  preset8: "#8764B8",
  preset9: "#F495BF",
  preset10: "#A0AEB2",
  preset11: "#004B60",
  preset12: "#B1ADAB",
  preset13: "#5D5A58",
  preset14: "#000000",
  preset15: "#750B1C",
  preset16: "#CA5010",
  preset17: "#AB620D",
  preset18: "#C19C00",
  preset19: "#004B1C",
  preset20: "#004B50",
  preset21: "#0B6A0B",
  preset22: "#002050",
  preset23: "#32145A",
  preset24: "#5C005C"
}