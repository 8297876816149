import React, { Component } from "react"
import { NavLink } from "react-router-dom"
import {
  Container,
  Menu,
  Visibility,
  Image
} from "semantic-ui-react"
import AuthButtons from "./AuthButtons"
import NavigationMenuItems from "./NavigationMenuItems"

export default class DesktopNavigationBar extends Component {

  hideFixedMenu = () => this.setState({ fixed: false })
  showFixedMenu = () => this.setState({ fixed: true })


  render() {
    const { children } = this.props

    return (
      <>
        <Visibility
          once={false}
          onBottomPassed={this.showFixedMenu}
          onBottomPassedReverse={this.hideFixedMenu}

        >
          <Menu
            fixed="top"
            inverted
            borderless
          >
            <Container>
              <Menu.Item as={NavLink} to="/" exact="true">
                <Image size="tiny" src="tascaly_logo.png" className="mr-2" />
              </Menu.Item>
              <NavigationMenuItems showMenu={this.props.showMenu} />
              <AuthButtons />
            </Container>
          </Menu>
          {/* </Segment> */}
        </Visibility>

        {children}
      </>
    )
  }
}
