import React from 'react'
import { List } from "semantic-ui-react"

function TodoistListDescription(props) {
  let text
  if (props.connected) {
    text = "Connected"
    if (props.timeboxSettingsCount > 0) {
      text = text + ", " + props.timeboxSettingsCount + " timebox" + (props.timeboxSettingsCount === 1 ? "" : "es") + " active"
    } else if (props.timeboxSettingsCount === 0) {
      text = text + ", no timeboxes set up!"
    }
  } else {
    text = "Not connected"
  }
  return (
    <List.Description>{text}</List.Description>
  )
}

export default TodoistListDescription

