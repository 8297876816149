import React, { Component } from 'react'
import { Header, Segment, Label, Button, Container } from "semantic-ui-react"
import { NavLink } from "react-router-dom"

export default class PricingCard extends Component {
  render() {
    const saveRibbon = this.props.save ?
    <Label ribbon color="green" style={{marginBottom: "0em", marginTop:"-2em"}}>{this.props.save}</Label> :
      <div style={{marginBottom:"1.75em"}}/>
    return (
      <Segment raised style={{marginLeft: "1em", marginRight: "1em"}}>
        {saveRibbon}
        <Container textAlign="center">
          {/* <Label size="large" color={"gray"} circular>
            {this.props.header}
          </Label> */}
        </Container>
        <Container textAlign="center">

          <Header as="h1" style={{
            fontSize: this.props.mobile ? "1.5em" : "3em",
            fontWeight: "bold",
            marginBottom: 0,
            marginTop: "0.0em"
          }}>
            {this.props.price}
          </Header>

          <Header style={{
            fontSize: this.props.mobile ? "0.7em" : "1.4em",
            fontWeight: "normal",
            marginBottom: "1.5em",
            marginTop: "-0.3em"
          }}>
            {this.props.period}
          </Header>

        </Container>
        <Container>
          {this.props.children}
        </Container>
        <Container textAlign="center" style={{
          marginTop: "2.5em",
          marginBottom: "0.5em"
        }}>
          <Button primary size='large' as={NavLink} to="/signup">
            Try for free
          </Button>
        </Container>
        <Container textAlign="center" style={{
          marginTop: "0em",
          marginBottom: "1.5em",
          fontSize: this.props.mobile ? "0.4em" : "0.8em",
          color: "#666666",
        }}>
          No credit card required
        </Container>
      </Segment>
    )
  }
}
