import * as actionTypes from "../actions/actionTypes";
export default function authReducer(state = null, action) {
  switch (action.type) {
    case actionTypes.FETCH_USER:
      return action.user || false;
    case actionTypes.REVOKE_ACCESS:
      return action.user || false;
    case actionTypes.ADD_TIMEBOX_SETTING:
      return action.user || false;
    case actionTypes.EDIT_TIMEBOX_SETTING:
      return action.user || false;
    case actionTypes.REMOVE_TIMEBOX_SETTING:
      return action.user || false;
    case actionTypes.SET_USE_TASK_DURATION_SETTING:
      return action.user || false;
    case actionTypes.EDIT_TASK_DURATION_LABEL:
      return action.user || false;
    case actionTypes.UPDATE_LABEL:
      return state;
    case actionTypes.ADD_LABEL:
      return state;
    case actionTypes.SET_CALENDAR:
      return action.user || false;
    case actionTypes.SET_EVENT_COLOR:
      return action.user || false;
    case actionTypes.SET_CATEGORY:
      return action.user || false;
    case actionTypes.SET_PRIVATE_SETTING:
      return action.user || false;
    case actionTypes.SET_TODOIST_LINK_DESCRIPTION_SETTING:
      return action.user || false;
    case actionTypes.SET_ON_COMPLETE_SETTING:
      return action.user || false;
    case actionTypes.SET_REMINDER:
      return action.user || false;
    case actionTypes.SET_ON_PRE_COMPLETE_SETTING:
      return action.user || false;
    case actionTypes.SET_SYNC_ACTIVE_SETTING:
      return action.user || false;
    case actionTypes.SET_WELCOME_WIZARD_SEEN:
      return action.user || false;
    case actionTypes.CREATE_PAYMENT_INTENT:
      return action.user || false;
    default:
      return state;
  }
}
