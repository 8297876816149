import React, { Component } from "react"
import { connect } from "react-redux"
import { NavLink } from "react-router-dom"
import {
  Button,
  Menu,
} from "semantic-ui-react"
import ProfileButton from "./ProfilePopup/ProfileButton"

class AuthButtons extends Component {
  render() {
    switch (this.props.auth) {
      case null:
        return null
      case false:
        return (
          <React.Fragment>
            <Menu.Item position="right">
              <Button as={NavLink} to="/login" basic inverted >
                Log in
              </Button>
              <Button as={NavLink} to="/signup" primary style={{ marginLeft: '0.7em' }}>
                Sign up
              </Button>
            </Menu.Item>
          </React.Fragment>
        )
      default:
        return (
          <Menu.Item position="right">
            <ProfileButton />
          </Menu.Item>
        )
    }
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  }
}

export default connect(mapStateToProps)(AuthButtons)