import React, { PureComponent } from 'react'
import {
  Button, CardGroup, Modal
} from "semantic-ui-react"
import SubscriptionTypeCard from './SubscriptionTypeCard'

export default class Subscription extends PureComponent {

  state = {
    lookupKey: "tascalyMonthly"
  }

  setLookupKey(lookupKey) {
    this.setState(
      { lookupKey: lookupKey }
    )
  }

  render() {
    return (
      <Modal size="tiny" open={this.props.open} onClose={this.props.onCancel}>
        <Modal.Header>
          Choose your subscription period
        </Modal.Header>
        <Modal.Content>
          <CardGroup stackable={false} itemsPerRow={2} style={{ marginBottom: '1.6rem' }}>
            <SubscriptionTypeCard
              active={this.state.lookupKey === "tascalyMonthly"}
              onSelectPeriod={() => this.setLookupKey("tascalyMonthly")}
              amountPerPeriodText="3$ per Month"
              subscriptionPeriodText="monthly"
            />
            <SubscriptionTypeCard
              active={this.state.lookupKey === "tascalyYearly"}
              onSelectPeriod={() => this.setLookupKey("tascalyYearly")}
              amountPerPeriodText="24$ per Year"
              subscriptionPeriodText="yearly"
            />
          </CardGroup>
          <p>
            By proceeding to and finishing checkout, you agree to be charged {this.state.lookupKey === "tascalyMonthly" ? "3$ each month " : "24$ each year "} 
            unless you cancel your subscription. You agree that refunds
            are not possible in case of cancellation. 
          </p>
        </Modal.Content>
        <Modal.Actions>
          <form action="/api/payment/create/checkout/session" method="POST">
            <input type="hidden" name="lookup_key" value={this.state.lookupKey} />
            <Button negative onClick={this.props.onCancel}>Cancel</Button>
            <Button primary id="checkout-and-portal-button" type="submit" style={{ marginLeft: '1rem' }}>
              Proceed to Checkout
            </Button>
          </form>
        </Modal.Actions>
      </Modal>
    )
  }
}
