import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  Label,
  Segment,
  Checkbox,
  Dropdown,
  Icon,
} from "semantic-ui-react";
import { colors } from "../UI/ColorMappings";

import * as actions from "../../redux/actions";

class TaskDurationSettings extends Component {
  state = {
    useTaskDurationSetting: false,
    useTaskDurationLoading: false,
    labelSetting: "all",
    labelLoading: false,
    labels: [],
    active: false,
  };

  createDropDownOptions = (user) => {
    let options = [];

    if (user?.todoSettings?.labels) {
      options = user.todoSettings.labels.map((label) => {
        return {
          key: label.id,
          text: label.name,
          value: label.name,
          icon: (
            <Icon
              name="tag"
              flipped="horizontally"
              style={{ color: colors[label.color].hexCode }}
            />
          ),
          order: label.item_order,
        };
      });
      options.push({
        key: "all",
        text: "Sync all tasks with task duration",
        value: "Sync all tasks with task duration",
        order: 0,
      });
      options.sort((a, b) =>
        a.order > b.order ? 1 : b.order > a.order ? -1 : 0
      );
    }

    return options;
  };

  onChangeUseTaskDuration = async (event, useTaskDurationSetting) => {
    this.setState({ useTaskDurationLoading: true });
    await this.props.setUseTaskDurationSetting(useTaskDurationSetting.checked);
    this.setState({
      useTaskDurationLoading: false,
      useTaskDurationSetting: useTaskDurationSetting.checked,
    });
  };

  onChangeLabel = async (event, label) => {
    this.setState({ labelLoading: true });
    const value = label.value;
    const { key } = this.state.labels.find((option) => option.value === value);

    await this.props.editTaskDurationLabel(key);

    this.setState({
      labelSetting: value,
      labelLoading: false,
    });
  };

  static getDerivedStateFromProps(props, state) {
    let labels = [];

    if (props.auth?.todoSettings?.labels) {
      labels = props.auth.todoSettings.labels.map((label) => {
        return {
          key: label.id,
          text: label.name,
          value: label.name,
          icon: (
            <Icon
              name="tag"
              flipped="horizontally"
              style={{ color: colors[label.color].hexCode }}
            />
          ),
          order: label.item_order,
        };
      });
      labels.push({
        key: "all",
        text: "Sync all tasks with task duration",
        value: "Sync all tasks with task duration",
        order: 0,
      });
      labels.sort((a, b) =>
        a.order > b.order ? 1 : b.order > a.order ? -1 : 0
      );
    }

    if (props.auth?.todoSettings?.taskDurationSettings) {
      return {
        useTaskDurationSetting:
          props.auth?.todoSettings?.taskDurationSettings?.active,
        labelSetting: props.auth?.todoSettings?.taskDurationSettings?.label,
        labels: labels,
        active:
          props.auth?.todoSettings?.accountType === "Business" ||
          props.auth?.todoSettings?.accountType === "Premium",
      };
    }
    return null;
  }

  render() {
    let premiumLabel;
    let labelForm;
    if (!this.state.active) {
      premiumLabel = (
        <Label
          active={!this.state.active}
          circular
          style={{
            backgroundColor: "#E8E8E8",
            color: "#5D5D5D",
            paddingTop: "1 rem"
          }}
        >
          Requires Todoist Premium or Business
        </Label>
      );
    } else {
      labelForm = (
        <Form.Group>
          <Form.Input
            label="Sync all tasks with task duration or only the ones with a specific label"
            width="12"
          >
            <Dropdown
              options={this.state.labels}
              placeholder="Sync all tasks with task duration"
              disabled={
                this.state.labelLoading ||
                !this.state.useTaskDurationSetting ||
                this.state.useTaskDurationLoading
              }
              // search
              selection
              // fluid
              // allowAdditions
              // additionLabel="Add new label: "
              value={
                this.state.labels.find(
                  (label) => label.key === this.state.labelSetting
                )?.value
              }
              // onAddItem={(event, { value }) => this.props.onNewLabel(event, { value })}
              onChange={(event, { value }) =>
                this.onChangeLabel(event, { value })
              }
              // onClick={this.props.onDropdownClicked}
            />
          </Form.Input>
        </Form.Group>
      );
    }

    return (
      <Segment raised>
        <div>
          <Label
            circular
            style={{
              backgroundColor: "#FFD150",
              color: "#8B6F1A",
              paddingTop: "1 rem",
            }}
          >
            🔥 New
          </Label>
          {premiumLabel}
          <br />
          <br />
        </div>
        <Form>
          <Form.Group>
            <Form.Input width="12">
              <Checkbox
                label="Use Todoist Task duration instead of timeboxing labels"
                checked={this.state.useTaskDurationSetting}
                onChange={this.onChangeUseTaskDuration}
                disabled={
                  this.state.useTaskDurationLoading ||
                  this.state.labelLoading ||
                  !this.state.active
                }
              />
            </Form.Input>
          </Form.Group>
          {labelForm}
        </Form>
      </Segment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, actions)(TaskDurationSettings);
