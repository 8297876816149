import React, { Component } from "react"
import { createMedia } from "@artsy/fresnel";
import {
  Container,
  Image,
  List,
  Segment
} from "semantic-ui-react"
import { NavLink } from "react-router-dom"

class Footer extends Component {

  render() {

    const AppMedia = createMedia({
      breakpoints: {
        mobile: 320,
        tablet: 768,
        computer: 992,
        largeScreen: 1200,
        widescreen: 1920
      }
    });

    const mediaStyles = AppMedia.createMediaStyle();
    const { Media, MediaContextProvider } = AppMedia;

    const footerLinks = (
      <React.Fragment>
        <List.Item active={false} as={NavLink} to="/homepage">
          tascaly.com
        </List.Item>
        <List.Item as={NavLink} to="/contact">
          Contact Us
        </List.Item>
        <List.Item as={NavLink} to="/legal">
          Legal Notice
        </List.Item>
        <List.Item as={NavLink} to="/terms">
          Terms and Conditions
        </List.Item>
        <List.Item as={NavLink} to="/privacy">
          Privacy Policy
        </List.Item>
        <List.Item as={NavLink} to="/documentation">
          Documentation
        </List.Item>
        <List.Item as={NavLink} to="/changelog">
          Changelog
        </List.Item>
        <List.Item as="a" href="https://twitter.com/tascaly" target="blank">
          <List.Icon name="twitter" />
        </List.Item>
      </React.Fragment>
    )

    return (
      <>
        <style>{mediaStyles}</style>
        <MediaContextProvider>
          <div style={{ marginTop: "auto" }}>
            <Segment inverted vertical style={{ margin: "5em 0em 0em", padding: "5em 0em" }}>
              <Container textAlign="center">
                <p style={{ marginBottom: "10px" }} >
                  <Image centered size="tiny" src="/tascaly_logo.png" />
                </p>
                <p style={{ color: "#FFFFFF80" }}>
                  Made with <span role="img" aria-label="love">❤️</span> in Germany
                </p>
                {/* Mobile Footer */}
                <List inverted link size="large" as={Media} at="mobile">
                  {footerLinks}
                </List>
                {/* Desktop Footer */}
                <List horizontal inverted divided link size="large" as={Media} greaterThanOrEqual="tablet">
                  {footerLinks}
                </List>
              </Container>
            </Segment>
          </div >
        </MediaContextProvider>
      </>
    )
  }
}

export default Footer