export const colors = {
  0: {
    id: "",
    name: "Default Calendar Color"
  }, 
  1: {
    id: "11",
    hexCode: "#dc2127",
    name: "Tomato"
  },
  2: {
    id: "4",
    hexCode: "#ff887c",
    name: "Flamingo"
  },
  3: {
    id: "6",
    hexCode: "#ffb878",
    name: "Tangerine"
  },
  4: {
    id: "5",
    hexCode: "#fbd75b",
    name: "Banana"
  },
  5: {
    id: "2",
    hexCode: "#7ae7bf",
    name: "Sage"
  },
  6: {
    id: "10",
    hexCode: "#51b749",
    name: "Basil"
  },
  7: {
    id: "7",
    hexCode: "#46d6db",
    name: "Peacock"
  },
  8: {
    id: "9",
    hexCode: "#5484ed",
    name: "Blueberry"
  },
  9: {
    id: "1",
    hexCode: "#a4bdfc",
    name: "Lavender"
  },
  10: {
    id: "3",
    hexCode: "#dbadff",
    name: "Grape"
  },
  11: {
    id: "8",
    hexCode: "#e1e1e1",
    name: "Graphite"
  },
}