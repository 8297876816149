import React, { Component } from 'react'
import { Checkbox } from "semantic-ui-react"
import { connect } from "react-redux"
import * as actions from "../../redux/actions"

class SyncSettings extends Component {

  state = {
    syncActive: false,
    syncActiveLoading: false
  }

  static getDerivedStateFromProps(props, state) {
    if (props.auth) {
      return ({
        syncActive: props.auth.syncActive
      })
    }
    return null
  }

  onChangeSyncActive = async (event, onSyncActiveSetting) => {
    this.setState({ syncActiveLoading: true })
    await this.props.setSyncActiveSetting(onSyncActiveSetting.checked)
    this.setState({
      syncActiveLoading: false,
      syncActive: onSyncActiveSetting.checked
    })
  }

  render() {
    return (
      <Checkbox
        toggle
        label="Everything set up? Activate your sync!"
        checked={this.state.syncActive}
        onChange={this.onChangeSyncActive}
        disabled={this.state.syncActiveLoading || this.props.disabled}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  }
}

export default connect(mapStateToProps, actions)(SyncSettings)
