import React, { createContext, useReducer, useEffect, useState, useContext } from "react"
import ReactGA from "react-ga";
import CookiePopup from "./CookiePopup"

const COOKIE_NAME = "tascaly-cookie-consent"

const CookieConsentStateContext = createContext()
const CookieConsentDispatchContext = createContext()

function getCookie() {
  const regex = new RegExp(`(?:(?:^|.*;\\s*)${COOKIE_NAME}\\s*\\=\\s*([^;]*).*$)|^.*$`)
  const cookie = document.cookie.replace(regex, "$1")
  return cookie.length ? JSON.parse(cookie) : undefined
}

// Initial value is cookie value OR prefered value but not yet set
let initialCookieValue = getCookie() || {
  isSet: false,
  analytical: false
}

const CookieConsentProvider = ({ children }) => {
  const [popupIsOpen, setPopupIsOpen] = useState(!initialCookieValue.isSet)

  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "accept":
        setPopupIsOpen(false)
        if (action.payload) {
          ReactGA.initialize("UA-170154099-1")
          ReactGA.pageview('Home');
        }
        return {
          ...state,
          isSet: true,
          analytical: action.payload
        }
      case "showCookiePopup":
        setPopupIsOpen(true)
        return state
      default:
        throw new Error()
    }
  }, initialCookieValue)

  // Update the cookie when state changes
  useEffect(() => {
    document.cookie = `${COOKIE_NAME}=${JSON.stringify(state)}`
  }, [state])

  return (
    <CookieConsentStateContext.Provider value={state}>
      <CookieConsentDispatchContext.Provider value={dispatch}>
        {children}
        {popupIsOpen && <CookiePopup dispatch={dispatch} />}
      </CookieConsentDispatchContext.Provider>
    </CookieConsentStateContext.Provider>
  )

}

function useCookieConsentState() {
  const context = useContext(CookieConsentStateContext)
  if (context === undefined) {
    throw new Error("useCookieConsentState must be used within CookieProvider")
  }
  return context
}

function useCookieConsentDispatch() {
  const context = useContext(CookieConsentDispatchContext)
  if (context === undefined) {
    throw new Error("useCookieConsentDispatch must be used within CookieProvider")
  }
  return context
}

export { CookieConsentProvider, useCookieConsentState, useCookieConsentDispatch }