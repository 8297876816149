import React, { Component } from "react"
import { Button, Modal, Checkbox, Icon } from 'semantic-ui-react'


class TimeboxSettingRemoveModal extends Component {

  render() {
    return (
      <Modal size="tiny" open={this.props.open} onClose={this.props.onCancel}>
        <Modal.Header>
          Remove Timebox setting for {" "} <Icon size="small" flipped="horizontally" name="tag" style={{ color: this.props.color }} />{" "}{this.props.label}
        </Modal.Header>
        <Modal.Content>
          <Checkbox
            onChange={this.props.onCheckboxChanged}
            disabled={!this.props.color || this.props.loading}
            label="Also delete label on Todoist? If not checked, this will leave the label and only delete the Timebox setting."
          />
        </Modal.Content>
        <Modal.Actions>
          <Button negative disabled={this.props.loading} onClick={this.props.onCancel}>Cancel</Button>
          <Button positive disabled={this.props.loading} loading={this.props.loading} onClick={this.props.onRemove}>Yes</Button>
        </Modal.Actions>
      </Modal>
    )
  }
}

export default TimeboxSettingRemoveModal
