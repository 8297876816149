import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Icon,
  Dropdown
} from "semantic-ui-react"
import { NavLink } from "react-router-dom"
import VersionLabel from './VersionLabel'


class ProfileButton extends Component {
  render() {

    return (
      <Dropdown trigger={<span><Icon name='user' /> {this.props.auth?.name?.firstName}</span>} pointing="top right">
        <Dropdown.Menu>
          {/* <Dropdown.Item content={ */}
          <React.Fragment>
            <div style={{ marginTop: "1em", marginBottom: "1em", marginLeft: "0.75em", textAlign: "center" }}>
              <Icon circular style={{ backgroundColor: "#429EBD", color: "white" }} name='user' />
            </div>
            <div style={{ marginLeft: "1em", marginRight: "1em", marginTop: "1em", marginBottom: "0.5em", textAlign: "center", color: "#202021" }}>
              {this.props.auth.name.displayName}
            </div>
            <div style={{ marginLeft: "1em", marginRight: "1em", marginBottom: "1em", textAlign: "center", color: "#969BA4" }}>
              {this.props.auth.email}
            </div>
          </React.Fragment>
          {/* } /> */}
          <Dropdown.Divider />
          <Dropdown.Item as={NavLink} to="/dashboard" icon="desktop" text="Dashboard" />
          <Dropdown.Item as={NavLink} to="/profile" icon="user" text="Your Profile" />
          <Dropdown.Divider />
          <Dropdown.Item as={NavLink} to="/documentation" icon="file alternate outline" text="Documentation" />
          <Dropdown.Item as={NavLink} to="/changelog">
            <VersionLabel />
            See changelog
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item as="a" href="/api/logout" icon="log out" text="Log out" />
        </Dropdown.Menu>
      </Dropdown>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  }
}


export default connect(mapStateToProps)(ProfileButton)
