import React from "react"
import {
  Grid, Card, Icon, Label
} from "semantic-ui-react"

export default function SubscriptionTypeCard(props) {
  return (
    <Card link color={props.active ? "blue" : false} onClick={props.onSelectPeriod} >
      <Card.Content>
        <Card.Header>
          <Grid columns={2}>
            <Grid.Column width={12}>
              {props.amountPerPeriodText}
            </Grid.Column>
            <Grid.Column width={4} floated="right" style={{textAlign: "right"}}>
              {
                props.active ?
                  <Icon name="check circle outline" color="blue"/> :
                  null
              }
            </Grid.Column>
          </Grid>
        </Card.Header>
        <Card.Meta>
          billed {props.subscriptionPeriodText}
          {
            props.subscriptionPeriodText === "yearly" ?
              <Label color="blue" size="tiny" style={{ marginLeft: '.6rem' }}>
                Save 33%
              </Label> :
              null
          }
        </Card.Meta>
      </Card.Content>
    </Card>
  )
}