import React from "react"
import { connect } from 'react-redux'
import DesktopNavigationBar from "./DesktopNavigationBar"
import MobileNavigationBar from "./MobileNavigationBar"
import { createMedia } from '@artsy/fresnel'

const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    computer: 1024,
  },
})

export const ResponsiveNavigationBar = (props) => {
  return (
    <MediaContextProvider>
      <Media at="mobile">
        <MobileNavigationBar showMenu={!props.auth}>{props.children}</MobileNavigationBar>
      </Media>
      <Media greaterThanOrEqual="tablet">
        <DesktopNavigationBar showMenu={!props.auth}>{props.children}</DesktopNavigationBar>
      </Media>
    </MediaContextProvider>
  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  }
}

export default connect(mapStateToProps)(ResponsiveNavigationBar)




