import React, { Component } from "react"
import { Popup, Table, Button, Icon, Grid } from "semantic-ui-react"
import { colors } from "../UI/ColorMappings"
import convertDuration from "./DurationConverter"


class TimeBoxSetting extends Component {

  onEditHandler = () => {
    this.props.onEdit(this.props.label, this.props.duration, this.props.todoistLabel?.color || null, this.labelExistsInTodoist)
  }

  onRemoveHandler = () => {
    this.props.onRemove(this.props.label, this.props.todoistLabel)
  }

  onAddHandler = () => {
    this.props.onAdd(this.props.label)
  }

  labelExistsInTodoist = false

  render() {
    this.labelExistsInTodoist = false
    if (this.props.todoistLabel) {
      this.labelExistsInTodoist = true
    }

    let color = null
    let icon = null
    if (this.props.todoistLabel) {
      color = colors[this.props.todoistLabel.color].hexCode
      icon = <Icon flipped='horizontally' name='tag' style={{ color: color }} />
    } else {
      icon = <Icon name="attention" />
    }


    return (
      <Table.Row warning={!this.props.todoistLabel}>
        <Popup
          hoverable
          position='left center'
          disabled={this.labelExistsInTodoist}
          trigger={
            <Table.Cell>
              {icon}
              {this.props.label}
            </Table.Cell>
          }>
          <Popup.Header>
            This label is not available on your Todoist account.
        </Popup.Header>
          <Popup.Content>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  You can create it yourself on your Todoist account and reload the page or click the button below.
              </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Button loading={this.props.loading} disabled={this.props.loading || this.props.buttonsDisabled} onClick={this.onAddHandler} fluid primary>Click here to create it</Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Popup.Content>
        </Popup>
        <Table.Cell>
          {this.props.duration}
          {" "}minute{this.props.duration === 1 ? "" : "s"}
        </Table.Cell>
        <Table.Cell>
          {convertDuration(this.props.duration)}
        </Table.Cell>
        <Table.Cell textAlign="right">
          <Button.Group basic>
            <Button
              icon
              onClick={this.onEditHandler}
              disabled={this.props.loading || this.props.buttonsDisabled}
              >
              <Icon name="pencil" />
            </Button>
            <Button
              icon
              onClick={() => this.onRemoveHandler(this.props.label)}
              disabled={this.props.loading || this.props.buttonsDisabled}
            >
              <Icon name="delete" />
            </Button>
          </Button.Group>
        </Table.Cell>
      </Table.Row >
    )
  }
}

export default TimeBoxSetting