import React, { Component } from "react"
import {
  Container, Grid, Header, Loader, Message, Button, Item, Label
} from "semantic-ui-react"
import moment from "moment"
import { SemanticToastContainer } from "react-semantic-toasts";
import "react-semantic-toasts/styles/react-semantic-alert.css";
import { connect } from "react-redux"
import { NavLink } from "react-router-dom"
import DeleteUserModal from "./DeleteUserModal"
import SubsctionModal from "./SubscriptionModal"

export class Profile extends Component {

  state = {
    deleteUserModalOpen: false,
    loading: false,
    updgradeSubscriptionModalOpen: false
  }

  // componentDidMount() {
  //   // const [searchParams] = useSearchParams()
  //   // const queryString = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).subscriptionState
  //   // let queryString = this.props.subscriptionState
  //   // TODO Abfrage mit möglichen Integrations abgleichen
  //   if (queryString === "success") {
  //     setTimeout(() => {
  //       toast({
  //         type: "success",
  //         icon: "check",
  //         title: "Congratulations!",
  //         description: "Your subscription is now active",
  //         animation: "fly left",
  //         time: 4000,
  //       })
  //     }, 500)
  //   } else if (queryString === "canceled") {
  //     setTimeout(() => {
  //       toast({
  //         type: "error",
  //         icon: "cancel",
  //         title: "Canceled",
  //         description: "You canceled the subscription process",
  //         animation: "fly left",
  //         time: 4000,
  //       })
  //     }, 500)
  //   }
  // }


  onOpenDeleteUserPopup = () => {
    this.setState({
      deleteUserModalOpen: true
    })
  }

  onCancelDeleteUser = () => {
    this.setState({
      deleteUserModalOpen: false
    })
  }

  onUpgradeSubscriptionPopup = () => {
    this.setState({
      updgradeSubscriptionModalOpen: true
    })
  }

  onCancelUpgradeSubscription = () => {
    this.setState({
      updgradeSubscriptionModalOpen: false
    })
  }

  render() {

    let provider

    let profile
    if (this.props.auth === null) {
      profile = (
        <Loader active size="medium">Loading</Loader>
      )
    } else if (this.props.auth === false) {
      profile = (
        <Message floating negative>
          <Message.Header>You are not logged in</Message.Header>
          <p>Please log in to access your account</p>
        </Message>
      )
    } else {
      if (this.props.auth.googleId) {
        provider = "Google"
      } else if (this.props.auth.facebookId) {
        provider = "Facebook"
      }

      let accountPlan
      let activeLabel
      let endsOnText
      let endsOnDate = moment(this.props.auth.payment.validUntil).format("MMMM Do YYYY")
      let stripeUserAvailable = true
      if (this.props.auth.payment.subscriptionStatus === "trial") {
        accountPlan = "Free Trial"
        endsOnText = "Trial ends on"
        stripeUserAvailable = false
      } else if (this.props.auth.payment.subscriptionStatus === "active") {
        if (this.props.auth.payment.plan?.interval === "month") {
          accountPlan = "Monthly Subscription"
        } else if (this.props.auth.payment.plan?.interval === "year") {
          accountPlan = "Yearly Subscription"
        }

        if (this.props.auth.payment.canceled) {
          activeLabel = <Label circular style={{ backgroundColor: "#D1D5DB", color: "#374151", marginLeft: "1rem" }}>canceled</Label>
          endsOnText = "Subscription ends on"
        } else {
          activeLabel = <Label circular style={{ backgroundColor: "#c6f6d5", color: "#22543d", marginLeft: "1rem" }}>active</Label>
          endsOnText = "Subscription is renewed on"
        }

      } else if (this.props.auth.payment.subscriptionStatus === "incomplete"
        || this.props.auth.payment.subscriptionStatus === "incomplete_expired"
        || this.props.auth.payment.subscriptionStatus === "unpaid"
        || this.props.auth.payment.subscriptionStatus === "past_due") {

        accountPlan = "Incomplete Subscription"
        activeLabel = <Label circular style={{ backgroundColor: "#fed7d7", color: "#742a2a", marginLeft: "1rem" }}>{this.props.auth.payment.subscriptionStatus.replace("_", " ")}</Label>
        endsOnText = "Subscription ends on"
      } else if (this.props.auth.payment.subscriptionStatus === "canceled") {
        accountPlan = "No active Subscription"
        endsOnDate = null
        stripeUserAvailable = false
      }

      profile = (
        <React.Fragment>

          <div style={{ marginBottom: "3em" }}>
            <Button as={NavLink} to="/" basic icon="left chevron" content="Go back to Dashboard" />
          </div>
          <Grid columns="equal" container divided="vertically" stackable>
            <Grid.Row>
              <Grid.Column mobile={8} tablet={3} computer={3} >
                <Header>Personal Data</Header>
              </Grid.Column>
              <Grid.Column mobile={8} tablet={13} computer={13} >
                <Item.Group>
                  <Item>
                    <Item.Content>
                      <Item.Meta>Name</Item.Meta>
                      <Item.Header>{this.props.auth.name.displayName}</Item.Header>
                    </Item.Content>
                  </Item>
                  <Item>
                    <Item.Content>
                      <Item.Meta>E-Mail</Item.Meta>
                      <Item.Header>{this.props.auth.email}</Item.Header>
                    </Item.Content>
                  </Item>
                  <Item>
                    <Item.Content>
                      <Item.Meta>Connected with</Item.Meta>
                      <Item.Header>{provider}</Item.Header>
                    </Item.Content>
                  </Item>
                </Item.Group>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column mobile={8} tablet={3} computer={3} >
                <Header>Subscription</Header>
              </Grid.Column>
              <Grid.Column mobile={8} tablet={13} computer={13} >
                <Item.Group>
                  <Item>
                    <Item.Content>
                      <Item.Meta>Plan</Item.Meta>
                      <Item.Header>{accountPlan}{activeLabel}</Item.Header>
                    </Item.Content>
                  </Item>
                  {endsOnText ?
                    <Item>
                      <Item.Content>
                        <Item.Meta>{endsOnText}</Item.Meta>
                        <Item.Header>{endsOnDate}</Item.Header>
                      </Item.Content>
                    </Item> : null}
                  <Item>
                    <Item.Header>
                      {stripeUserAvailable ?
                        <form action="/api/payment/customerportal" method="POST">
                          <Button icon="credit card" primary content="Manage your Subscription" type="submit" />
                        </form> :
                        <Button onClick={this.onUpgradeSubscriptionPopup} icon="star outline" primary content="Upgrade" />
                      }
                    </Item.Header>
                  </Item>
                </Item.Group>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column mobile={8} tablet={3} computer={3} >
                <Header>Danger Zone!</Header>
              </Grid.Column>
              <Grid.Column mobile={8} tablet={13} computer={13} >
                <div style={{ marginBottom: "1em" }}>
                  If you delete your account, it"s permanent. All data will be lost and you cannot use Tascaly anymore. Any payments done so far cannot be reimbursed. All your relevant Todoist tasks and calendar events will not be touched.
                </div>
                <div>
                  <Button basic color="red" onClick={this.onOpenDeleteUserPopup}>
                    Delete your account
                  </Button>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <SubsctionModal
            open={this.state.updgradeSubscriptionModalOpen}
            onCancel={this.onCancelUpgradeSubscription} />
          <SemanticToastContainer
            position="top-right"
            animation="fly right"
            className="floating"
          />
        </React.Fragment>
      )
    }

    return (
      <React.Fragment>
        <Container style={{ marginTop: "7em" }}>
          {profile}
        </Container >
        <DeleteUserModal
          open={this.state.deleteUserModalOpen}
          onCancel={this.onCancelDeleteUser}
        />
      </React.Fragment >
    )
  }
}


const mapStateToProps = (state) => {
  return {
    auth: state.auth
  }
}

export default connect(mapStateToProps)(Profile)
