import React, { Component } from 'react'
// import ReactMarkdown from "react-markdown"
import {
  Container,
  Header
} from "semantic-ui-react"


export default class Terms extends Component {


  render() {


    return (
      <Container text style={{ marginTop: "7em" }}>
        <Header>
          Have any questions? Report a bug?
          </Header>
        <p>
        Send us an email at <a href="mailto:mail@tascaly.com">mail@tascaly.com</a>
        </p>
      </Container>
    )
  }

}

