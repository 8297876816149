import * as actionTypes from "../actions/actionTypes"
import { updateObject } from "../util/utility"

const initialState = {
  timeBoxSettings: []
}

export default function settingsReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.ADD_TIMEBOX_SETTING: {
      const newTimeBoxSetting = { label: action.label, duration: action.duration }
      const currentTimeBoxSettings = state.timeBoxSettings
      currentTimeBoxSettings.push(newTimeBoxSetting)
      const updatedState = { timeBoxSettings: currentTimeBoxSettings }
      return updateObject(state, updatedState)
    }
    case actionTypes.REMOVE_TIMEBOX_SETTING: {
      const currentTimeBoxSettings = state.timeBoxSettings
      const index = currentTimeBoxSettings.findIndex(setting => setting.label === action.label)
      if (index >= 0) {
        currentTimeBoxSettings.splice(index, 1)
      }
      const updatedState = { timeBoxSettings: currentTimeBoxSettings }
      return updateObject(state, updatedState)
    }
    case actionTypes.EDIT_TIMEBOX_SETTING: {
      const currentTimeBoxSettings = state.timeBoxSettings
      const index = currentTimeBoxSettings.findIndex(setting => setting.label === action.oldLabel)
      if (index >= 0) {
        currentTimeBoxSettings[index] = { label: action.newLabel, duration: action.duration }
      }
      const updatedState = { timeBoxSettings: currentTimeBoxSettings }
      return updateObject(state, updatedState)
    }
    default:
      return state
  }
}
